import { first, map } from "lodash";
import { useCallback, useMemo } from "react";

import { Entity, ID } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { toTemplateViewId } from "@state/views";
import { SystemPackages, useAnyHasPackages } from "@state/packages";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useLazyEntity, useLazyQuery } from "@state/generic";
import { useActiveSpace } from "@state/spaces";
import { toDisplayName, useMe } from "@state/persons";

import { isViewId, isWorkspaceId } from "@utils/id";
import { useGoTo, usePushTo } from "@utils/navigation";
import { Maybe } from "@utils/maybe";
import { withoutStar } from "@utils/wildcards";
import { Fn } from "@utils/fn";
import { omitEmpty } from "@utils/object";
import { useStickyState } from "@utils/hooks";

import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { PageLayout, Main, TopBar } from "@ui/page-layout";
import { Icon, PersonIcon } from "@ui/icon";
import { Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Heading, Text } from "@ui/text";
import { ViewCardCompact } from "@ui/view-card-compact";
import { TabbedViewCard } from "@ui/view-card";
import { ParentCard } from "@ui/parent-card";
import { Switch } from "@ui/switch";

import AppPage from "./app-page";

import styles from "./home-page.module.css";

interface Props {}

const HomePage = ({}: Props) => {
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const [page] = useRegisterPage("home", undefined);
  const space = useActiveSpace();
  const [showAll, setShowAll] = useStickyState<boolean>(false, `home-show-all`);
  const scopes = useMemo(
    () =>
      space.scopes === "*"
        ? map(me.teams, (t) => t.id)
        : withoutStar(space.scopes),
    [space.id]
  );

  usePageUndoRedo(page.id);

  // Ensure current user is always fetched on first load
  useLazyEntity(me.id, false);

  if (!me) {
    return <></>;
  }

  return (
    <AppPage page={page} title="Home">
      <PageLayout bar="top">
        <TopBar>
          <WrapContainer>
            <Container stack={"horizontal"} inset="bottom">
              <SpaceBetween>
                <HStack gap={20} fit="container">
                  <HStack>
                    {!!me && (
                      <Icon icon={<PersonIcon person={me} />} size="xxlarge" />
                    )}
                    <VStack gap={0}>
                      <Heading bold>{me?.name || "Home"}</Heading>
                      <HStack>
                        <Text subtle>@{toDisplayName(me)}</Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </HStack>

                {/* <Quote height="content" size="full" /> */}
                {!isWorkspaceId(space.id) && (
                  <Switch
                    className={styles.switch}
                    size="small"
                    checked={showAll}
                    onChange={setShowAll}
                    label="Show work from all teams"
                    labelPosition="before"
                    subtle
                  />
                )}
              </SpaceBetween>
            </Container>
            {/* </CoverImage> */}
          </WrapContainer>
        </TopBar>

        <Main>
          <HomePanes filter={showAll ? wId : space.id} scopes={scopes} />
        </Main>
      </PageLayout>
    </AppPage>
  );
};

interface HomePanesProps {
  filter?: ID;
  scopes: Maybe<ID[]>;
}

const HomePanes = ({ filter, scopes }: HomePanesProps) => {
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const me = useCurrentUser();
  const workspaceId = useActiveWorkspaceId();

  const toCheck = useMemo(
    () =>
      filter && !isWorkspaceId(filter)
        ? [workspaceId, filter]
        : [workspaceId, ...(scopes || [])],
    [workspaceId, scopes]
  );
  const installed = useAnyHasPackages(toCheck, [
    SystemPackages.Meetings,
    SystemPackages.Outcomes,
    SystemPackages.Content,
    SystemPackages.Events,
    SystemPackages.Deals,
    SystemPackages.Campaigns,
    SystemPackages.Sprints,
    SystemPackages.Projects,
  ]);

  const viewParams = useMemo(
    () =>
      filter && isWorkspaceId(filter)
        ? // If just looking at all my tasks can do this
          { parent: me.id, for: me.id }
        : // If filtering to a team need to setup view differently
          omitEmpty({ parent: filter, for: me.id }),
    [me, filter]
  );

  const toId = useCallback(
    (template: string) => toTemplateViewId(template, viewParams),
    [viewParams]
  );

  const taskViewIds = useMemo(
    () => [toId("my-tasks"), toId("my-recently-completed"), toId("my-planned")],
    [toId]
  );

  return (
    <WrapContainer>
      <SpaceBetween fit="container" align="stretch" gap={16}>
        <Sheet size="primary" mode="sizing" height="content">
          <VStack gap={10}>
            <TabbedViewCard
              viewIds={taskViewIds}
              onOpen={pushTo}
              limit={5}
              size="full"
            />

            {!!installed[SystemPackages.Meetings] && (
              <ViewCardCompact
                viewId={toId("my-actions")}
                onOpen={pushTo}
                empty="collapse"
                size="full"
              />
            )}

            {!!installed[SystemPackages.Deals] && (
              <ViewCardCompact
                viewId={toId("my-deals")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Content] && (
              <ViewCardCompact
                viewId={toId("my-contents")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Events] && (
              <ViewCardCompact
                viewId={toId("my-events")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}
          </VStack>
        </Sheet>
        <Sheet size="secondary" mode="sizing" height="content">
          <VStack fit="container">
            {installed[SystemPackages.Sprints] && (
              <CurrentSprintCard onClick={pushTo} />
            )}

            {!!installed[SystemPackages.Meetings] && (
              <>
                <ViewCardCompact
                  viewId={toId("my-today-meetings")}
                  onOpen={(ref) =>
                    isViewId(ref.id) ? goTo("meetings") : pushTo(ref)
                  }
                  empty="collapse"
                  size="full"
                />
              </>
            )}

            {!!installed[SystemPackages.Campaigns] && (
              <ViewCardCompact
                viewId={toId("my-campaigns")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Projects] && (
              <ViewCardCompact
                viewId={toId("my-projects")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Outcomes] && (
              <ViewCardCompact
                viewId={toId("my-outcomes")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}
          </VStack>
        </Sheet>
      </SpaceBetween>
    </WrapContainer>
  );
};

const CurrentSprintCard = ({ onClick }: { onClick: Fn<Entity, void> }) => {
  const sprints = useLazyQuery("sprint", {
    field: "status",
    type: "status",
    op: "equals",
    value: { status: { group: "in-progress" } },
  });
  const sprint = useMemo(() => first(sprints), [sprints]);

  if (!sprint) {
    return <></>;
  }

  return (
    <ParentCard
      id={sprint.id}
      className={styles.sprintcard}
      label="Current"
      onClick={onClick}
    />
  );
};

export default HomePage;
