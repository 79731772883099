import { Portal } from "@radix-ui/react-portal";
import { camelCase } from "change-case";
import {
  CSSProperties,
  Dispatch,
  DOMAttributes,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { useThemeColor } from "@state/settings";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import {
  useCancellableTimeout,
  useClickAway,
  useMousePosition,
} from "@utils/hooks";
import { respectHandled, useShortcut, useWindowEvent } from "@utils/event";

import styles from "./sheet-layout.module.css";

interface PageLayoutProps {
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
  onScroll?: () => void;
  extra?: React.ReactNode;
}

export interface SheetProps {
  size?:
    | "content"
    | "full"
    | "primary"
    | "primary-thicc"
    | "primary-thin"
    | "secondary";
  height?: "container" | "content";
  mode?: "card" | "sizing";
  transparency?: "none" | "low" | "mid" | "high";
  interactable?: boolean;
  onClick?: Fn<React.MouseEvent, void>;
  children?: React.ReactNode;
}

export const StackContainer = ({
  className,
  children,
  style,
}: PageLayoutProps) => (
  <div className={cx(styles.stackContainer, className)} style={style}>
    {children}
  </div>
);

// Used for stacking sheets vertically or horizontally, still sets width/height
export const StackSheets = ({
  children,
  size = "full",
  height,
  className,
}: SheetProps & { className?: string }) => (
  <div
    className={cx(
      styles.stackSheets,
      styles[camelCase(size)],
      styles[height + "Y"],
      className
    )}
  >
    {children}
  </div>
);

export const WrapContainer = ({
  className,
  children,
  style,
}: PageLayoutProps) => (
  <div className={cx(styles.wrapContainer, className)} style={style}>
    {children}
  </div>
);

export const Nav = ({ className, children }: PageLayoutProps) => (
  <div className={cx(styles.nav, className)}>{children}</div>
);

export const Sheet = forwardRef(
  (
    {
      className,
      size = "full",
      height = "container",
      mode = "card",
      interactable = true,
      transparency = [
        "primary",
        "full",
        "primary-thicc",
        "primary-thin",
      ]?.includes(size)
        ? "low"
        : "mid",
      style,
      children,
      onClick,
      ...rest
    }: PageLayoutProps &
      SheetProps &
      Omit<DOMAttributes<HTMLDivElement>, "onClick">,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const themeColor = useThemeColor();
    return (
      <div
        {...rest}
        ref={ref}
        className={
          mode !== "card"
            ? cx(
                styles.sheet,
                styles.sizingMode,
                styles[height + "Y"],
                styles[camelCase(size)]
              )
            : cx(
                styles.sheet,
                styles[camelCase(size)],
                styles[transparency + "Transparency"],
                !!themeColor && transparency !== "none" && styles.glassy,
                styles[interactable ? "interactable" : "noInteract"],
                styles[mode + "Mode"],
                styles[height + "Y"],
                className
              )
        }
        style={style}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

interface FocsableSheetProps {
  focused?: boolean;
  setFocused?: Dispatch<boolean>;
}

export const FocusableSheet = ({
  onClick,
  focused,
  setFocused,
  className,
  ...props
}: PageLayoutProps & SheetProps & FocsableSheetProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = !!setFocused
    ? [focused, setFocused]
    : useState(false);
  useClickAway(ref, () => setIsFocused(false));

  return (
    <Sheet
      ref={ref}
      {...props}
      onClick={(e) => {
        setIsFocused(true);
        onClick?.(e);
      }}
      className={cx(styles?.focusable, isFocused && styles.focused, className)}
    />
  );
};

interface ClickableSheetProps {
  onClick?: Fn<React.MouseEvent, void>;
}

export const ClickableSheet = ({
  onClick,
  className,
  ...props
}: PageLayoutProps & SheetProps & ClickableSheetProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Sheet
      {...props}
      ref={ref}
      onClick={respectHandled((e) => {
        e && onClick?.(e);
      })}
      className={cx(styles?.clickable, className)}
    />
  );
};

export const OverlaySheet = ({
  className,
  onDismiss,
  ...props
}: PageLayoutProps & SheetProps & { onDismiss?: Fn<void, void> }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className={styles.overlaySheetDimmer}
        onClick={() => onDismiss?.()}
      ></div>
      <Sheet
        ref={ref}
        {...props}
        transparency="low"
        className={cx(styles?.overlaySheet, className)}
      />
    </>
  );
};

export const SlideInSheet = ({
  visible,
  setVisible,
  children,
  className,
  ...props
}: PageLayoutProps &
  SheetProps & { visible: boolean; setVisible?: Fn<boolean, void> }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [firstRender, setFirstRender] = useState(true);

  const [autoOpen, cancel] = useCancellableTimeout(
    () => setVisible?.(true),
    200,
    [setVisible]
  );

  useClickAway(ref, () => setVisible?.(false));
  useShortcut("Escape", [() => visible, () => setVisible?.(false)], [visible]);

  useLayoutEffect(() => {
    if (firstRender) {
      setTimeout(() => setFirstRender(false), 10);
    }
  }, []);

  const pullSideSheet = useCallback(
    (e: MouseEvent) => {
      if (visible) {
        ref?.current?.style.setProperty("transform", null);
        ref?.current?.style.setProperty("opacity", null);
        return;
      }

      const fromRight = window.innerWidth - e.clientX;
      const fromRightPercent = (fromRight / window.innerWidth) * 100;

      // No peaking
      if (fromRightPercent > 20) {
        cancel();
        ref?.current?.style.setProperty("transform", `translateX(0%)`);
        return;
      }

      ref?.current?.style.setProperty(
        "transform",
        `translateX(${-Math.min((1 / fromRightPercent) * 100, 14)}px)`
      );
      ref?.current?.style.setProperty("opacity", `1`);

      if (fromRight < 30) {
        autoOpen();
      }
    },
    [ref, visible, cancel]
  );

  useMousePosition(pullSideSheet);
  useWindowEvent("mouseout", cancel);

  // Clears above overrides
  useLayoutEffect(() => {
    if (visible) {
      ref?.current?.style.setProperty("transform", null);
      ref?.current?.style.setProperty("opacity", null);
    }
  }, [visible]);

  return (
    <Portal data-ignore-auto-close>
      <Sheet
        ref={ref}
        {...props}
        transparency="high"
        interactable={false}
        className={cx(
          styles?.slideInSheet,
          !firstRender && visible && styles.visible,
          className
        )}
      >
        <div className={styles.contents}>{children}</div>
      </Sheet>
    </Portal>
  );
};
