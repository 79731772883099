import { filter, groupBy, map, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { ID } from "@api";

import {
  PACKAGES,
  Package,
  usePackageInstaller,
  usePackageUninstaller,
} from "@state/packages";
import { useEntityLabels, useEntitySettings } from "@state/settings";
import { useFetchAllEntityProperties } from "@state/databases";

import { cx } from "@utils/class-names";
import { useShortcut } from "@utils/event";
import { getSetting } from "@utils/property-refs";
import { switchEnum } from "@utils/logic";
import { maybeMap, when } from "@utils/maybe";
import { fuzzyMatch } from "@utils/search";
import { Fn } from "@utils/fn";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Heading, Text, TextSmall } from "@ui/text";
import { Box } from "@ui/icon";
import { Label } from "@ui/label";
import { Modal } from "@ui/modal";
import { Ellipsis } from "@ui/ellipsis";
import { Card } from "@ui/card";
import { SearchInput } from "@ui/search-input";
import { Tag } from "@ui/tag";
import { Switch } from "@ui/switch";

import styles from "./packages-marketplace.module.css";

export function PackagesMarketplace({
  scope,
  onClose,
}: {
  scope: ID;
  onClose?: Fn<void, void>;
}) {
  const pageId = usePageId();
  const [search, setSearch] = useState<string>();
  const [showInstalled, setShowInstalled] = useState(false);
  const settings = useEntitySettings(scope);
  const { install, installing } = usePackageInstaller(scope, pageId);
  const { uninstall, uninstalling } = usePackageUninstaller(scope, pageId);
  const toLabel = useEntityLabels(scope);

  useFetchAllEntityProperties(scope);

  const packages = useMemo(
    () =>
      maybeMap(PACKAGES, (p) => ({
        ...p,
        installed: Boolean(getSetting<boolean>(settings, p.id)),
      })),
    [settings]
  );

  const filtered = useMemo(
    () =>
      filter(
        packages,
        (p) =>
          (showInstalled || !p.installed) &&
          (!search || fuzzyMatch(search, p.name + " " + p.description))
      ),
    [packages, search, showInstalled]
  );

  const groups = useMemo(
    () =>
      orderBy(
        map(
          groupBy(filtered, (p) =>
            p.type === "custom" ? p.tags?.[0] || "other" : p.type
          ),
          (packages, type) => ({
            title: switchEnum(type || "", {
              entity: () => "Core Work",
              integration: () => "Integrations",
              os: () => "Operating Systems",
              clone: () => "Migrating From",
              else: () => "Other",
            }),
            type: type,
            packages,
          })
        ),
        (g) =>
          switchEnum(g.type, {
            os: 1,
            clone: 2,
            entity: 3,
            integration: 4,
            else: 5,
          })
      ),
    [filtered, map(filtered, (f) => f.id).join(",")]
  );

  const handleInstall = useCallback((id: ID) => install(id), [install]);

  const handleUninstall = useCallback((id: ID) => uninstall(id), [uninstall]);

  useShortcut("Escape", [() => true, () => onClose?.()], [onClose]);

  return (
    <Modal
      open
      className={styles.modal}
      onOpenChanged={(o) => !o && onClose?.()}
    >
      <Container size="double" gap={20} stack="vertical">
        <SpaceBetween>
          <VStack gap={0}>
            <HStack fit="content">
              <Heading bold>Marketplace</Heading>
              <Tag size="small" color="orange_3">
                Beta
              </Tag>
            </HStack>
            <Text subtle>Find and install best-practice team operations.</Text>
          </VStack>

          <HStack>
            <Switch
              subtle
              label="Show installed"
              labelPosition="before"
              size="small"
              checked={showInstalled}
              onChange={setShowInstalled}
            />
            <SearchInput search={search} setSearch={setSearch} />
          </HStack>
        </SpaceBetween>
        {map(groups, ({ title, packages }) => (
          <CollapsibleSection key={title} title={title}>
            <Container
              padding="none"
              stack="horizontal"
              wrap
              fit="container"
              gap={6}
              inset="left"
            >
              {map(packages, (p) => (
                <PackageCard
                  key={p.id}
                  package={p}
                  installed={p.installed}
                  alias={when(p.entity, toLabel)}
                  onInstalled={(id, install) =>
                    install ? handleInstall(id) : handleUninstall(id)
                  }
                  loading={uninstalling === p.id}
                  className={styles.thirdWidth}
                />
              ))}
            </Container>
          </CollapsibleSection>
        ))}
      </Container>
    </Modal>
  );
}

interface PackageProps {
  package: Package;
  alias?: string;
  installed: boolean;
  loading?: boolean;
  onInstalled: (id: ID, installed: boolean) => void;
  className?: string;
}

function PackageCard({
  package: { id, name, description, icon, available = true },
  alias,
  installed,
  onInstalled,
  className,
  loading,
}: PackageProps) {
  return (
    <Card className={cx(className)} interactable={false} fit="content">
      <SpaceBetween fit="container">
        <FillSpace direction="horizontal">
          <VStack gap={0} fit="container">
            <SpaceBetween>
              <Label subtle={!available} bold icon={icon || Box}>
                {name}
                {alias && !name?.includes(alias) ? ` (${alias})` : ""}
              </Label>
              {!available && (
                <Tag size="small">
                  <TextSmall subtle>Coming...</TextSmall>
                </Tag>
              )}
            </SpaceBetween>
            {!installed && (
              <Ellipsis>
                <TextSmall subtle>{description}</TextSmall>
              </Ellipsis>
            )}
          </VStack>
        </FillSpace>
        {installed && (
          <Button
            variant="danger"
            size="small"
            subtle
            onClick={() => onInstalled(id, false)}
            loading={loading}
          >
            Uninstall
          </Button>
        )}
        {!installed && available && (
          <Button
            subtle
            variant="primary"
            size="small"
            disabled={!available}
            onClick={() => onInstalled(id, true)}
            loading={loading}
          >
            Install
          </Button>
        )}
      </SpaceBetween>
    </Card>
  );
}
