import { useCallback, useMemo, useState } from "react";

import { ID, Team, toTitleOrName } from "@api";

import { useActiveSpace } from "@state/spaces";
import { toShortLabel } from "@state/teams";
import { useCurrentWorkspace } from "@state/workspace";

import { Maybe, safeAs, when } from "@utils/maybe";
import { ifDo } from "@utils/logic";
import { fallback } from "@utils/fn";
import { isWorkspaceId, typeFromId } from "@utils/id";
import { useGoTo, usePathName } from "@utils/navigation";
import { useShortcut } from "@utils/event";

import { Button } from "@ui/button";
import {
  AngleDoubleRight,
  ArrowVertical,
  Icon,
  ImageIcon,
  PlusIcon,
  TeamIcon,
  Traction,
} from "@ui/icon";
import { Container } from "@ui/container";
import { SpaceBetween, VStack } from "@ui/flex";
import { TextMedium, TextSmall } from "@ui/text";
import { SpacesOverlay } from "@ui/spaces-overlay";

import styles from "./spaces-select.module.css";

interface Props {
  collapsed?: boolean;
  onCollapse: (collapsed: boolean) => void;
}

const toIcon = (url: Maybe<string>) =>
  fallback(
    () => ifDo(url === "add", () => <PlusIcon />),
    () => when(url, (url) => <ImageIcon url={url} />),
    () => <Traction />
  );

export const SpacesSelect = ({ collapsed, onCollapse }: Props) => {
  const [open, setOpen] = useState(false);
  const goTo = useGoTo();
  const space = useActiveSpace();
  const workspace = useCurrentWorkspace();
  const path = usePathName();
  const mode = useMemo(() => typeFromId(space.id), [space?.id]);

  useShortcut({ key: "Backquote" }, () => setOpen((open) => !open), []);
  useShortcut({ key: "Escape" }, [() => open, () => setOpen(false)], [open]);

  const handleSpaceChanged = useCallback(
    (id: ID) => {
      if (
        id !== space.id &&
        !path?.startsWith("/home") &&
        !path?.startsWith("/meetings")
      ) {
        goTo(isWorkspaceId(id) ? "/home" : id);
      }
    },
    [space.id, path]
  );

  return (
    <Container className={styles.spaceContainer}>
      {collapsed && (
        <Button fit="content" subtle onClick={() => onCollapse(false)}>
          <Icon icon={AngleDoubleRight} size="medium" />
        </Button>
      )}

      {!collapsed && (
        <SpaceBetween>
          <Button
            onClick={() => setOpen(true)}
            className={styles.spaceButton}
            iconRight={ArrowVertical}
            iconSize="xsmall"
            subtle
            size="regular"
            fit="container"
          >
            <SpaceBetween gap={6}>
              {mode === "workspace" && (
                <>
                  <Icon
                    size="large"
                    className={styles.spaceIcon}
                    icon={<TeamIcon team={safeAs<Team>(space.entity)} />}
                  />
                  <VStack gap={0}>
                    <TextMedium bold>All Teams</TextMedium>
                    <TextSmall subtle>
                      {when(space.entity, toTitleOrName) || "Workspace"}
                    </TextSmall>
                  </VStack>
                </>
              )}

              {mode === "person" && (
                <>
                  <Icon
                    size="large"
                    className={styles.spaceIcon}
                    icon={<TeamIcon team={safeAs<Team>(space.entity)} />}
                  />
                  <VStack gap={0}>
                    <TextMedium bold>Private</TextMedium>
                    <TextSmall subtle>
                      {when(space.entity, toTitleOrName) || "Workspace"}
                    </TextSmall>
                  </VStack>
                </>
              )}

              {mode === "team" && (
                <>
                  <Icon
                    size="large"
                    className={styles.spaceIcon}
                    icon={<TeamIcon team={safeAs<Team>(space.entity)} />}
                  />
                  <VStack gap={0}>
                    <TextMedium bold>
                      {when(safeAs<Team>(space.entity), toShortLabel)}
                    </TextMedium>
                    <TextSmall subtle>
                      {workspace?.name || "Workspace"}
                    </TextSmall>
                  </VStack>
                </>
              )}
            </SpaceBetween>
          </Button>
          {/* 
          <Button
            subtle
            icon={AngleDoubleLeft}
            onClick={() => onCollapse(true)}
          /> */}
        </SpaceBetween>
      )}

      {open && (
        <SpacesOverlay
          onClose={() => setOpen(false)}
          onChanged={handleSpaceChanged}
        />
      )}
    </Container>
  );
};
