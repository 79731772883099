import { isEqual, isString, map } from "lodash";
import { useLocation } from "react-router-dom";
import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  DatabaseID,
  Entity,
  EntityRef,
  ID,
  Project,
  Task,
  Team,
  Update,
  View,
  Note,
  Person,
  Workspace,
  Resource,
  Outcome,
  Backlog,
  Sprint,
  PropertyDef,
  Calendar,
  Campaign,
  Content,
  Roadmap,
  Action,
  Agenda,
  Meeting,
  Page,
  Process,
  KnowledgeBase,
  Form,
} from "@api";

import { MeetingStoreAtom } from "@state/meetings";
import { AgendaStoreAtom } from "@state/agendas";
import { ActionStoreAtom } from "@state/actions";
import { reverseUpdate, useQueueUpdateEffect } from "@state/store";
import { WorkspaceStoreAtom } from "@state/workspace";
import { TaskStoreAtom } from "@state/tasks";
import { ProjectStoreAtom } from "@state/projects";
import { TeamStoreAtom } from "@state/teams";
import { ViewStoreAtom } from "@state/views";
import { NoteStoreAtom } from "@state/notes";
import { CampaignStoreAtom } from "@state/campaigns";
import { CalendarStoreAtom } from "@state/calendar";
import { ContentStoreAtom } from "@state/content";
import { ResourceStoreAtom } from "@state/resources";
import { PersonStoreAtom } from "@state/persons";
import { OutcomeStoreAtom } from "@state/outcomes";
import { useGetItemFromAnyStore, useQueueUpdates } from "@state/generic";
import { RoadmapStoreAtom } from "@state/roadmap";
import { BacklogStoreAtom } from "@state/backlog";
import { SprintStoreAtom } from "@state/sprint";
import { PageStoreAtom } from "@state/page";
import { ProcessStoreAtom } from "@state/process";
import { KnowledgeBaseStoreAtom } from "@state/knowledgebase";
import { FormStoreAtom } from "@state/form";

import { Maybe, maybeMap, when } from "@utils/maybe";
import { toArray } from "@utils/set";
import { newUndoStack, redo, undo } from "@utils/undo";
import { useShortcut } from "@utils/event";
import { switchEnum } from "@utils/logic";
import { isSelected, SelectionState } from "@utils/selectable";
import { newID } from "@utils/id";

import { usePageId } from "@ui/app-page";

import {
  addToStack,
  editPropertyInCmdK,
  popPage,
  pushPage,
  setCommandsAction,
  setCommandsOpen,
  setMode,
  setPageEntity,
  setPageSelected,
  setUndoStack,
} from "./actions";
import { AppCommandsAction, AppPage } from "./types";
import { AppCommandsAtom, AppLocationAtom, AppPageAtom } from "./atoms";
import { currentPage } from "./selectors";

export const usePageUndoRedo = (pageId: ID) => {
  const current = usePageId();
  const [page, setPage] = useRecoilState(AppPageAtom(pageId));
  const queueUpdate = useQueueUpdates();

  const undoLast = useCallback(() => {
    const [updates, stack] = page ? undo(page.undoStack) : [];

    // Skip if nothing to undo
    if (!updates || !stack) {
      return;
    }

    // Invert the last action
    map(updates, (update) => {
      const reversed = reverseUpdate(update);
      queueUpdate(reversed);
    });

    // Update undo stack
    setPage(setUndoStack(stack));
  }, [queueUpdate, setPage, page?.undoStack]);

  const redoLast = useCallback(() => {
    const [updates, stack] = page ? redo(page.undoStack) : [];

    // Skip if nothing to redo
    if (!updates || !stack) {
      return;
    }

    // Re-submit the update
    map(updates, (update) => queueUpdate(update));

    // Update undo stack
    setPage(setUndoStack(stack));
  }, [queueUpdate, setPage, page?.undoStack]);

  // Only bind shortcuts when current page
  useShortcut(
    { command: true, key: "KeyZ" },
    [() => current === pageId, () => undoLast()],
    [current, undoLast]
  );
  useShortcut(
    { command: true, shift: true, key: "KeyZ" },
    [() => current === pageId, () => redoLast()],
    [current, undoLast]
  );

  return {
    undoLast,
    redoLast,
  };
};

export const useRegisterPage = <T extends Entity>(
  _id?: ID,
  entity?: Maybe<T>
): [AppPage, Dispatch<AppPage>] => {
  const location = useLocation();
  const localPage = useMemo<AppPage>(
    () => ({
      id: newID(),
      path: location.pathname || "",
      undoStack: newUndoStack(),
      entity: when(entity, (e) => ({
        id: e.id,
        source: e.source as DatabaseID,
        type: e.source?.type,
        name:
          (e as { name: Maybe<string> }).name ||
          (e as { title: Maybe<string> }).title,
      })),
    }),
    []
  );

  const setAppLocation = useSetRecoilState(AppLocationAtom);
  const [page, setPage] = useRecoilState(AppPageAtom(localPage.id));

  // Register and push page
  useEffect(() => {
    setAppLocation(pushPage(localPage));

    return () => setAppLocation(popPage(localPage));
  }, [location]);

  // Update page entity if set after load
  useEffect(() => {
    if (entity && page?.entity?.id !== entity?.id) {
      setPage(
        setPageEntity({
          id: entity.id,
          source: entity.source as DatabaseID,
          type: entity.source.type,
          name:
            (entity as { name: Maybe<string> }).name ||
            (entity as { title: Maybe<string> }).title,
        } as EntityRef)
      );
    }
  }, [page?.entity, entity]);

  return [page || localPage, setPage];
};

export const useActivePage = (pageId: Maybe<ID>) => {
  const page = useRecoilValue(currentPage);
  return page?.id === pageId;
};

export const useSyncPageSelection = (pageId: ID, selection: SelectionState) => {
  const [page, setPage] = useRecoilState(AppPageAtom(pageId));
  const getItem = useGetItemFromAnyStore();

  useEffect(() => {
    if (
      !isEqual(
        Array.from(selection.selected),
        map(page?.selected, (a) => a.id)
      )
    ) {
      setPage(
        setPageSelected(
          maybeMap(toArray(selection.selected), (id) =>
            when(
              getItem(id),
              (item): EntityRef => ({
                id: item.id,
                source: item.source,
              })
            )
          )
        )
      );
    }
  }, [pageId, selection.selected]);
};

export const useAppCommandsAction = (
  entity?: Entity,
  selection?: SelectionState
) => {
  const setAppCommands = useSetRecoilState(AppCommandsAtom);

  return useCallback(
    (action: AppCommandsAction) => {
      if (selection && entity && isSelected(selection, entity?.id)) {
        setAppCommands(setCommandsAction(action, true));
      } else {
        setAppCommands(setCommandsAction(action, true, entity));
      }
    },
    [entity, selection]
  );
};

export const useOpenAppCommands = (entity?: Entity) => {
  const setCommands = useSetRecoilState(AppCommandsAtom);

  return useCallback(
    () => setCommands(setCommandsOpen(true, entity)),
    [setCommands]
  );
};
export const useOpenCmdK = useOpenAppCommands;

export const useOpenSearch = () => {
  const setCommands = useSetRecoilState(AppCommandsAtom);

  return useCallback(() => setCommands(setMode("searching")), []);
};

export const useOpenRecents = () => {
  const setCommands = useSetRecoilState(AppCommandsAtom);

  return useCallback(() => setCommands(setMode("recents")), []);
};

export const useEditInAppCommands = () => {
  const setCommands = useSetRecoilState(AppCommandsAtom);

  return useCallback(
    (prop?: PropertyDef<Entity> | AppCommandsAction, entity?: EntityRef) => {
      if (!prop) {
        setCommands(setMode("commands"));
      } else if (isString(prop)) {
        setCommands(setCommandsAction(prop, true, entity));
      } else {
        setCommands(editPropertyInCmdK(prop, entity));
      }
    },
    [setCommands]
  );
};

export const useMoveLocation = () => {
  const editInCommands = useEditInAppCommands();
  return useCallback(() => editInCommands("move_location"), []);
};

export const useDelete = (entity?: Entity, selection?: SelectionState) => {
  const setAction = useAppCommandsAction(entity, selection);
  return useCallback(() => setAction("delete"), []);
};
