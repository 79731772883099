import { useRecoilValue, useSetRecoilState } from "recoil";

import { ID } from "@api";

import { useLazyEntity } from "@state/generic";

import { typeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";

import { AppSpaceAtom } from "./atoms";

export const useActiveSpace = () => {
  const space = useRecoilValue(AppSpaceAtom);
  const entity = useLazyEntity(space.id);

  return { ...space, mode: entity?.source.type, entity };
};

export const useSetSpace = () => {
  const setSpace = useSetRecoilState(AppSpaceAtom);

  return (id: ID) =>
    setSpace({
      id,
      scopes: switchEnum(typeFromId(id), {
        workspace: "*",
        team: [id],
        "person:": [id],
        else: () => [],
      }),
    });
};

export const useSpaceState = () => {
  const space = useActiveSpace();
  const setSpace = useSetSpace();

  return [space, setSpace] as const;
};
