import { flatMap, groupBy, map, reduce, uniq, values } from "lodash";
import { useCallback, useMemo } from "react";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

import {
  DatabaseID,
  Entity,
  EntityType,
  ID,
  Integration,
  persist,
  PropertyMutation,
  Ref,
  Update,
  isError,
  PersonRole,
} from "@api";
import { EntityForType, TypeForEntity } from "@api/mappings";

import { addToStack, AppPageAtom } from "@state/app";
import { PropertyDefStoreAtom, useLazyProperties } from "@state/databases";
import {
  discardUpdate,
  failUpdate,
  finishChangeUpdate,
  finishCreate,
  finishRestore,
  finishUpdate,
  getItem,
  handleError,
  nextUpdatesToProcess,
  queueUpdates,
  retryUpdate,
  startUpdate,
  StoreState,
  syncUpdate,
  withPersistedID,
  withPersistedTimestamp,
} from "@state/store";
import {
  MaybeActiveWorkspaceSessionAtom,
  useActiveWorkspaceId,
  useCurrentUser,
} from "@state/workspace";
import { TeamStoreAtom } from "@state/teams/atoms";
import { ViewAtom } from "@state/views/atoms";
import {
  useDidUpdateWorkflows,
  useWillUpdateWorkflows,
} from "@state/workflows";
import { useRealtimeChannel } from "@state/realtime";
import { FetchResultsStoreAtom } from "@state/fetch-results";

import { useAsyncEffect } from "@utils/effects";
import { fallback } from "@utils/fn";
import { isLocalID, maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { all } from "@utils/promise";
import {
  asTempUpdate,
  asUpdate,
  flattenUpdate,
  toMutation,
  withPrevValues,
} from "@utils/property-mutations";
import { ensureMany, OneOrMany, pushDirty } from "@utils/array";
import { isEmptyRef } from "@utils/property-refs";
import { log, debug, warn } from "@utils/debug";
import { toBaseScope, toChildLocation } from "@utils/scope";

import { showError } from "@ui/notifications";

import { EntityStores, getStore } from "../atoms";
import { isUpdateRelevant, shouldSyncUpdate } from "../utils";
import { useLazyEntity } from "./fetching";

export function useSource(type: EntityType, scope: string): DatabaseID;
export function useSource(
  type: Maybe<EntityType>,
  scope: Maybe<string>
): Maybe<DatabaseID>;
export function useSource(type: Maybe<EntityType>, scope: Maybe<string>) {
  return useMemo(
    () => (type && scope ? { type, scope } : undefined),
    [type, scope]
  );
}

export function useEntitySource<T extends EntityType>(
  type: T,
  source?: DatabaseID
): DatabaseID<T>;
export function useEntitySource<T extends EntityType>(
  type: Maybe<T>,
  source?: DatabaseID
): Maybe<DatabaseID<T>>;
export function useEntitySource<T extends EntityType>(
  type: Maybe<T>,
  source?: DatabaseID
) {
  const workspace = useActiveWorkspaceId();

  return useMemo(
    () =>
      type
        ? ({
            integration: Integration.Traction,
            scope: workspace,
            ...source,
            type,
          } as DatabaseID<T>)
        : undefined,
    [type, source?.scope, source?.type]
  );
}

export function useNestedSource<
  E extends Entity,
  C extends EntityType = TypeForEntity<E>
>(item: E, childType?: Maybe<C>): DatabaseID<C>;
export function useNestedSource<
  E extends Entity,
  C extends EntityType = TypeForEntity<E>
>(item: Maybe<E>, childType?: Maybe<C>): Maybe<DatabaseID<C>>;
export function useNestedSource(
  item: Maybe<Entity>,
  childType?: Maybe<EntityType>
): Maybe<DatabaseID> {
  return useMemo(
    () =>
      item && {
        type: childType ?? item?.source.type,
        scope: toChildLocation(item.source.scope, item.id),
      },
    [item?.source.scope, item?.id, childType]
  );
}

export function useBaseSource(item: Maybe<Entity>): Maybe<DatabaseID> {
  return useMemo(
    () =>
      item && {
        type: item?.source.type,
        scope: toBaseScope(item.source.scope),
      },
    [item?.source.scope, item?.id]
  );
}

export function useStore<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(t: T): StoreState<E> {
  const Store = useMemo(() => getStore<T, E>(t), [t]);
  return useRecoilValue(Store);
}

export function useGetItemFromAnyStore() {
  const stores = useAllStores();
  return useCallback(
    <T extends Entity>(id: ID): Maybe<T> => {
      const type = maybeTypeFromId<EntityType>(id);
      return when(type && stores[type], (store) =>
        getItem<Entity>(store as StoreState<Entity>, id)
      ) as Maybe<T>;
    },
    [stores]
  );
}

export function useEntityStores() {
  return useRecoilValue(EntityStores);
}

export function useAllStores() {
  const props = useRecoilValue(PropertyDefStoreAtom);
  const fetchResults = useRecoilValue(FetchResultsStoreAtom);
  const entityStores = useRecoilValue(EntityStores);

  return useMemo(
    () => ({ props, fetchResults, ...entityStores }),
    [props, fetchResults, entityStores]
  );
}

export function useAllUpdatesToProcess() {
  const allStores = useEntityStores();
  return flatMap(
    values(allStores) as StoreState<Entity>[],
    (a) => a?.unsaved || []
  );
}

interface ProcessUpdatesResult {
  updating: Update<Entity>[];
  aliases: Record<string, string>;
  history: Update<Entity>[];
  unsaved: Update<Entity>[];
  saving: boolean;
}

export function useCombinedStore() {
  const stores = useEntityStores();
  return useMemo(
    () =>
      reduce(
        values(stores) as StoreState<Entity>[],
        (r, s) => ({
          updating: pushDirty(r.updating, ...(s.updating || [])),
          aliases: { ...r.aliases, ...s.aliases },
          history: pushDirty(r.history, ...(s.history || [])),
          unsaved: pushDirty(r.unsaved, ...(s.unsaved || [])),
          saving: r.saving || !!s.updating?.length,
        }),
        {
          updating: [],
          aliases: {},
          history: [],
          unsaved: [],
          saving: false,
        } as ProcessUpdatesResult
      ),
    [stores]
  );
}

export const useAllowedScopes = () => {
  const session = useRecoilValue(MaybeActiveWorkspaceSessionAtom);
  const teams = useRecoilValue(TeamStoreAtom);

  if (!session || !session.workspace || !session.user) {
    return [];
  }
  const { user: me, workspace } = session;

  const allTeamIds = useMemo(
    () =>
      equalsAny(me.role, [PersonRole.Owner, PersonRole.Admin])
        ? maybeMap(values(teams.lookup), (t) => t?.id)
        : [],
    [me.role, teams.lookup]
  );

  return useMemo(() => {
    if (me.role === "guest") {
      return [...map(me.teams, (t) => t.id), me.id];
    }

    if (equalsAny(me.role, [PersonRole.Owner, PersonRole.Admin])) {
      return uniq([
        ...allTeamIds,
        ...map(me.teams, (t) => t.id),
        workspace.id,
        me.id,
      ]);
    }

    return [...map(me.teams, (t) => t.id), workspace.id, me.id];
  }, [me.id, me.role, me.teams, workspace.id, allTeamIds.length]);
};

export function useProcessUpdates() {
  const workspace = useActiveWorkspaceId();
  const combined = useCombinedStore();
  const me = useCurrentUser();
  const allowedScopes = useAllowedScopes();
  const getPostSaveChanges = useDidUpdateWorkflows();

  const { send } = useRealtimeChannel(
    `${workspace}`,
    "sync_update",
    ({ update }) => {
      if (isUpdateRelevant(update, allowedScopes)) {
        setRecoil(getStore(update.source.type), syncUpdate(update));
      }
    }
  );

  useAsyncEffect(async () => {
    const { unsaved, aliases, saving } = combined;
    // Update already in progress
    if (saving) {
      return;
    }

    const next = nextUpdatesToProcess(unsaved, aliases);

    // Temp updates now stay in queue, could filter them out if we want this warning back
    // if (!next.length && unsaved.length) {
    // debug("Non-failing update stuck in queue!", unsaved);
    // }

    // Mark all updates as started
    const toPersist = maybeMap(next, (u) => {
      // Always mark update as started (even when immediately finishing below)
      // You can't finish something you never started 🧘
      setRecoil(getStore(u.source.type), startUpdate(u));

      // Don't make any requests for apply mutations
      if (u.method === "apply") {
        setRecoil(getStore(u.source.type), finishChangeUpdate(u));
        return undefined;
      }

      // Don't make any requests for mutations that are just syncing with other clients changes
      if (u.mode === "sync") {
        setRecoil(getStore(u.source.type), finishChangeUpdate(u));
        return undefined;
      }

      // Skip when deleting an unpersisted entity
      if (u.method === "delete" && isLocalID(u.id)) {
        setRecoil(getStore(u.source.type), finishUpdate(u, undefined));
        return undefined;
      }

      return u;
    });

    // No changes to persist
    if (!toPersist.length) {
      return;
    }

    try {
      // Persist all in one request, using update with persisted IDs
      const results = await persist(
        map(toPersist, (u) => withPersistedID(u, aliases))
      );

      if (results?.length !== toPersist?.length) {
        warn("Different number of process results/updates.");
      }

      // Update all stores with the results (immediuately)
      map(results, (saved, i) => {
        const update = toPersist[i] as Maybe<Update<Entity>>;

        if (!update) {
          log("Missing update found for result", { saved, toPersist });
          return undefined;
        }

        // Updated failed...
        if (isError(saved)) {
          debug("Error saving update", update, saved);

          return setRecoil(
            getStore(update.source.type),
            handleError(update, saved)
          );
        }

        // Finish application of update
        setRecoil(
          getStore(update.source.type),
          switchEnum(update.method, {
            create: () => finishCreate(update, saved),
            update: () => finishChangeUpdate(update, saved),
            delete: () => finishUpdate(update, saved),
            restore: () => finishRestore(update, saved),
            else: () => finishUpdate(update, saved),
          })
        );

        if (shouldSyncUpdate(update, aliases)) {
          // Push successful updates to all clients with persisted ID
          send({
            sender: me.id,
            update: withPersistedTimestamp(
              withPersistedID(update, aliases),
              saved
            ),
          });
        }
      });

      // Process post-save workflow updates.
      await all(
        map(results, async (saved, i) => {
          const update = toPersist[i] as Maybe<Update<Entity>>;

          if (!update) {
            return undefined;
          }

          // Updated failed...
          if (isError(saved)) {
            debug("Error saving update", update, saved);
            return setRecoil(
              getStore(update.source.type),
              handleError(update, saved)
            );
          }

          const asyncChanges = await getPostSaveChanges(saved, update);
          setRecoil(getStore(update.source.type), queueUpdates(asyncChanges));
        })
      );
    } catch (err) {
      log(err);
      map(next, (u) => {
        setRecoil(
          getStore(u.source.type),
          isError(err) ? handleError(u, err) : failUpdate(u)
        );
      });
    }
  }, [combined.saving, combined.unsaved, combined.aliases, combined.updating]);

  return combined;
}

// Queues updates but also runs all change workflows before
export function useQueueUpdates<T extends Entity>(
  pageId?: ID,
  skipWorkflows?: boolean
) {
  const setPage = useSetRecoilState(AppPageAtom(pageId || ""));
  const getAdditionalUpdates = useWillUpdateWorkflows<T>();

  return useCallback(
    (update: OneOrMany<Update<T>>) => {
      const updates = reduce(
        ensureMany(update),
        (res, u) => {
          const entity = fallback(
            () => getItem(getRecoil(getStore(u.source.type)), u.id) as Maybe<T>,

            () =>
              u.source.type === "view"
                ? (getRecoil(ViewAtom(u.id)) as T)
                : undefined,

            () => (u.method === "create" ? (flattenUpdate(u) as T) : undefined)
          );
          // Populate the prev value on all changes
          const update =
            entity && u.method !== "create" ? withPrevValues(entity, u) : u;

          // Don't queue empty updates
          if (update.method === "update" && !update.changes?.length) {
            return res;
          }

          // Add to core updates
          pushDirty(res.core, update);

          if (!entity) {
            debug(
              "Warning: Skipping update triggers as entity is not present."
            );
          }

          if (entity && !skipWorkflows) {
            const additional = getAdditionalUpdates(entity, update);
            pushDirty(
              res.additional,
              ...map(additional || [], (u) => withPrevValues(entity, u))
            );
          }

          return res;
        },
        { core: [] as Update<T>[], additional: [] as Update<T>[] }
      );

      const all = [...updates.core, ...updates.additional];
      const byStore = groupBy(
        all,
        (u) => u?.source.type || maybeTypeFromId(u.id)
      );

      // Add all updates to their respective stores.
      map(byStore, (updates, type) => {
        const Store = when(type as EntityType, getStore) as Maybe<
          RecoilState<StoreState<T>>
        >;

        if (!Store) {
          throw new Error(`Store unknown for update (${type}).`);
        }
        setRecoil(Store, queueUpdates(updates));
      });

      // Add all updates to the history stack
      setPage(addToStack(all));

      return all;
    },
    [setPage]
  );
}

export function useUpdateEntity(id: ID, pageId?: ID, temp?: boolean) {
  const entity = useLazyEntity(id);
  const mutate = useQueueUpdates(pageId);

  return useCallback(
    <T extends Entity = Entity>(changes: OneOrMany<PropertyMutation<T>>) => {
      return entity
        ? mutate(
            !temp
              ? asUpdate<Entity>(
                  entity,
                  ensureMany(changes) as PropertyMutation<Entity>[]
                )
              : asTempUpdate(
                  entity,
                  ensureMany(changes) as PropertyMutation<Entity>[]
                )
          )
        : undefined;
    },
    [entity?.id, entity?.source]
  );
}

export function useUpdateFromObject(source: Maybe<DatabaseID>, pageId?: ID) {
  const mutate = useQueueUpdates(pageId);
  const props = useLazyProperties(source);

  return useCallback(
    <T extends Entity = Entity>(item: Ref, changesObj: Partial<T>) => {
      if (!source) {
        showError("Not ready to update.");
        return;
      }

      const changes = maybeMap(props, (p) => {
        const mut = toMutation(changesObj as T, p);
        return !isEmptyRef(mut) ? mut : undefined;
      });

      return mutate({
        id: item.id,
        source: source,
        method: "update",
        changes: changes as PropertyMutation<Entity>[],
      });
    },
    [props]
  );
}

export function useRetryUpdate<T extends Entity = Entity>() {
  return useCallback((update: Update<T>) => {
    const Store = when(
      update.source.type || typeFromId(update?.id),
      getStore
    ) as Maybe<RecoilState<StoreState<T>>>;

    if (!Store) {
      return;
    }

    setRecoil(Store, retryUpdate(update));
  }, []);
}

export function useDiscardUpdate<T extends Entity = Entity>() {
  return useCallback((updates: OneOrMany<Update<T>>) => {
    map(ensureMany(updates), (update) => {
      const Store = when(
        update.source.type || typeFromId(update?.id),
        getStore
      ) as Maybe<RecoilState<StoreState<T>>>;

      if (!Store) {
        return;
      }

      setRecoil(Store, discardUpdate(update));
    });
  }, []);
}
