import { HasResources, ID, Link, Ref, Resource, hasResources } from "@api";
import { find, flatMap, last, map, without } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  useDeleteEntitys,
  useLazyEntities,
  useLazyEntity,
} from "@state/generic";
import { useAttachFile, useAttachLink } from "@state/resources";
import { useRelatedEntities } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";
import { SystemPackages, useHasPackages } from "@state/packages";

import { Fn } from "@utils/fn";
import { fromScope } from "@utils/scope";
import { withHandle } from "@utils/event";
import { Maybe } from "@utils/maybe";
import { useShowMore } from "@utils/hooks";
import { uniqRefs } from "@utils/relation-ref";

import { usePageId } from "@ui/app-page";
import { Container } from "@ui/container";
import { HStack } from "@ui/flex";
import { CheckIcon, Icon, OpenIn } from "@ui/icon";
import { PaneHeader } from "@ui/pane-header";
import { AddResourcesButton } from "@ui/resources-add-button";
import { Sheet } from "@ui/sheet-layout";
import { useGoTo } from "@utils/navigation";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { Label } from "@ui/label";
import { Button } from "@ui/button";
import { Text } from "@ui/text";
import { LinkItem } from "@ui/link-item";
import { render, useEngine } from "@ui/engine";
import { useSyncPaneCount } from "@ui/pane-manager";
import { ResourceExtractor } from "./resource-extractor";
import { UploadFileButton } from "./upload-modal";

import styles from "./resources-pane.module.css";

const isLink = (r: Resource): r is Resource & { url: string } => !!r?.url;

interface Props {
  location: ID;
  onSelected?: Fn<Resource, void>;
}

export const ResourcesPane = ({ location, onSelected }: Props) => {
  const goTo = useGoTo();
  const pageId = usePageId();
  const workspace = useActiveWorkspaceId();
  const [includeRelated, setIncludeRelated] = useState(true);
  const [_suggestedLinks, setSuggestedLinks] = useState<Link[]>([]);
  const suggestedLinks = useShowMore(_suggestedLinks, 5);
  const entityId = useMemo(() => last(fromScope(location)), [location]);
  const installed = useHasPackages(entityId || workspace, [
    SystemPackages.Notion,
  ]);

  const entity = useLazyEntity(entityId);
  const related = useRelatedEntities(includeRelated ? entityId : undefined);
  const asResourceable = useMemo(
    () => (hasResources(entity) ? entity : undefined),
    [entity]
  );
  const onDelete = useDeleteEntitys(pageId);
  const onLinkAdded = useAttachLink(asResourceable, pageId);
  const onFileUploaded = useAttachFile(entity as Maybe<HasResources>, pageId);
  const allResources = useMemo(
    () =>
      includeRelated
        ? uniqRefs(
            flatMap(
              related,
              (e) => (hasResources(e) && e.refs?.resources) || []
            )
          )
        : (entity as HasResources)?.refs?.resources || [],
    [includeRelated, entity, related]
  );
  const _resources = useLazyEntities<"resource">(allResources || []);
  const resources = useShowMore(_resources || [], 5);
  const engine = useEngine("resource");

  const onResourceSelected = useCallback(
    (r: Ref) => {
      const resource = find(_resources, { id: r.id });

      if (resource && isLink(resource)) {
        goTo(resource.url);
      } else if (resource && !!onSelected) {
        onSelected?.(resource);
      } else {
        goTo(r);
      }
    },
    [onSelected]
  );

  // Sync the count with the pane manager
  useSyncPaneCount(_resources?.length);

  return (
    <Sheet size="secondary" height="content" className={styles.pane}>
      <PaneHeader title="Attachments">
        <Label
          subtle
          fit="content"
          iconRight={<CheckIcon checked={includeRelated} />}
          onClick={() => setIncludeRelated(!includeRelated)}
        >
          Include related
        </Label>
      </PaneHeader>

      <Container stack="vertical" gap={20} className={styles.paneContainer}>
        <Menu>
          <MenuGroup>
            {!!resources.visible?.length &&
              map(resources.visible, (r) =>
                render(engine.asMenuItem, {
                  key: r.id,
                  item: r,
                  onOpen: onResourceSelected,
                  onDelete: (r) => onDelete([r.id]),
                })
              )}
            {resources.hasMore && (
              <ShowMoreMenuItem
                count={resources.moreCount}
                onClick={resources.showMore}
              />
            )}

            {!resources.visible?.length && (
              <EmptyMenuItem>
                No resources{includeRelated && " here or on all related work"}.
              </EmptyMenuItem>
            )}
          </MenuGroup>

          {!!suggestedLinks.visible?.length && (
            <MenuGroup label="Suggested">
              {map(suggestedLinks.visible, (r) => (
                <LinkItem
                  key={r.url}
                  link={r}
                  onClick={() => goTo(r?.url)}
                  actions={
                    <HStack gap={2}>
                      <Icon icon={<OpenIn />} />
                      <Button
                        onClick={withHandle(() => {
                          onLinkAdded(r);
                          setSuggestedLinks(without(_suggestedLinks, r));
                        })}
                        size="tiny"
                      >
                        Attach
                      </Button>
                    </HStack>
                  }
                />
              ))}
              {suggestedLinks.hasMore && (
                <ShowMoreMenuItem
                  count={suggestedLinks.moreCount}
                  onClick={suggestedLinks.showMore}
                />
              )}
            </MenuGroup>
          )}
        </Menu>
      </Container>

      {entity && (
        <Container stack="vertical" className={styles.paneActions} inset="left">
          <ResourceExtractor
            entityId={entity.id}
            onExtracted={setSuggestedLinks}
          />
          <HStack fit="container" gap={0} overflow="scroll">
            <AddResourcesButton
              entity={entity}
              mode="new-link"
              defaultPinned={true}
              onAddLink={onLinkAdded}
            >
              Link to
            </AddResourcesButton>

            <UploadFileButton
              scope={entity.source.scope}
              onUploaded={onFileUploaded}
            >
              <Text>Upload</Text>
            </UploadFileButton>
          </HStack>
        </Container>
      )}
    </Sheet>
  );
};
