import { map } from "lodash";

import { EntityType, Ref, Team } from "@api";

import { useLazyEntities, useUpdateEntity } from "@state/generic";
import { useEntityLabels } from "@state/settings";

import { asAppendMutation } from "@utils/property-mutations";
import { Fn } from "@utils/fn";
import { useGoTo, usePushTo } from "@utils/navigation";
import { typeFromId } from "@utils/id";
import { respectHandled } from "@utils/event";
import { when } from "@utils/maybe";
import { toNotionUrl } from "@utils/notion";
import { toPropertyValueRef } from "@utils/property-refs";
import { toSlackUrl } from "@utils/slack";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Container } from "@ui/container";
import { Card } from "@ui/card";
import { Heading, SectionLabel, Text } from "@ui/text";
import { RelationLabel } from "@ui/relation-label";
import {
  ArrowUpRight,
  Cog,
  ExitLeft,
  Icon,
  Notion,
  PanesFilled,
  PinSlash,
  PlusIcon,
  SlackColor,
  TeamIcon,
} from "@ui/icon";
import { GlobalEntitySelect } from "@ui/select";
import { Button } from "@ui/button";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { ColorSelect } from "@ui/select/color";

import styles from "./team-header.module.css";
import { useActiveSpace, useSetSpace } from "@state/spaces";
import { useActiveWorkspaceId } from "@state/workspace";

interface Props {
  team: Team;
}

export const TeamHeader = ({ team }: Props) => {
  const goTo = useGoTo();
  const wId = useActiveWorkspaceId();
  const pushTo = usePushTo();
  const pinned = useLazyEntities(team?.refs?.pins || []);
  const mutate = useUpdateEntity(team.id);

  const space = useActiveSpace();
  const setSpace = useSetSpace();

  if (!team) {
    return <h1>Not found.</h1>;
  }

  return (
    <Container padding="none" inset="top">
      <VStack gap={40}>
        <VStack gap={6} className={styles.teamName}>
          <SpaceBetween>
            <HStack>
              <ColorSelect
                color="green"
                onChange={(color) =>
                  mutate({
                    field: "color",
                    type: "text",
                    value: { text: color },
                  })
                }
              >
                {team && <Icon icon={<TeamIcon team={team} />} size="large" />}
              </ColorSelect>
              <Heading bold>Team Home</Heading>
            </HStack>

            <HStack gap={4}>
              {space.id !== team.id && (
                <Button
                  size="small"
                  icon={PanesFilled}
                  onClick={() => setSpace(team.id)}
                >
                  Enter Team Space
                </Button>
              )}

              {space.id === team.id && (
                <Button
                  size="small"
                  icon={ExitLeft}
                  onClick={() => setSpace(wId)}
                >
                  Leave Team Space
                </Button>
              )}

              <Button
                size="small"
                icon={Cog}
                onClick={() => pushTo("/settings/data")}
              >
                Settings
              </Button>
              {team?.notionId && (
                <Button
                  icon={Notion}
                  subtle
                  onClick={respectHandled(() =>
                    when(team?.notionId, (n) => goTo(toNotionUrl(n)))
                  )}
                />
              )}
              {when(
                team &&
                  toPropertyValueRef(team, {
                    field: "settings.channel",
                    type: "text",
                  }),
                ({ value }) => (
                  <Button
                    icon={SlackColor}
                    subtle
                    onClick={respectHandled(() =>
                      when(value.text, (n) => goTo(toSlackUrl(n)))
                    )}
                  />
                )
              )}
            </HStack>
          </SpaceBetween>
        </VStack>

        <Divider />

        <PinnedItems
          items={pinned || []}
          onOpen={goTo}
          onPin={(r) =>
            mutate(
              asAppendMutation({ field: "refs.pins", type: "relations" }, [r])
            )
          }
          onUnpin={(r) =>
            mutate(
              asAppendMutation(
                { field: "refs.pins", type: "relations" },
                [r],
                "remove"
              )
            )
          }
          scope={team.id}
        />
      </VStack>
    </Container>
  );
};

interface PinnedItemsProps {
  items: Ref[];
  onOpen: Fn<Ref, void>;
  onPin: Fn<Ref, void>;
  onUnpin: Fn<Ref, void>;
  scope: string;
}
const PinnedItems = ({
  items,
  scope,
  onOpen,
  onPin,
  onUnpin,
}: PinnedItemsProps) => {
  const toEntityLabel = useEntityLabels(scope);
  return (
    <Container
      className={styles.section}
      padding="none"
      stack="vertical"
      gap={2}
      fit="container"
    >
      <Container padding="none" inset="top" fit="container">
        <SpaceBetween>
          <SectionLabel text={items?.length ? "Pinned" : ""} />

          <GlobalEntitySelect
            value={undefined}
            scope={scope}
            onChange={(r) => r && onPin(r)}
            showOtherTeams={false}
            showTeam={false}
            portal={true}
          >
            <Button icon={PlusIcon} subtle size="small">
              <Text subtle>Add pin</Text>
            </Button>
          </GlobalEntitySelect>
        </SpaceBetween>
      </Container>

      <HStack gap={6} wrap fit="container">
        {map(items, (item) => (
          <ContextMenu
            key={item.id}
            actions={
              <>
                <ContextItem icon={PinSlash} onClick={() => onUnpin(item)}>
                  Unpin
                </ContextItem>
              </>
            }
          >
            <Card
              onClick={() => onOpen?.(item)}
              className={styles.pinnedItem}
              fit="container"
            >
              <RelationLabel
                fit="container"
                relation={item}
                iconRight={ArrowUpRight}
              />
              <Text subtle>
                {toEntityLabel(typeFromId<EntityType>(item.id))}
              </Text>
            </Card>
          </ContextMenu>
        ))}
      </HStack>
    </Container>
  );
};
