import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { Entity, EntityType, FetchOptions, FilterQuery, ID, Ref } from "@api";
import * as api from "@api";
import { EntityForType } from "@api/mappings";

import { getStore } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { mergeItems, StoreState } from "@state/store";

import { useAsyncEffect } from "@utils/effects";
import { hashable } from "@utils/serializable";
import { ensureMany, OneOrMany, pickIds } from "@utils/array";
import { Maybe, when } from "@utils/maybe";
import { now } from "@utils/date-fp";
import { Fn } from "@utils/fn";

import {
  FetchResultsAtom,
  FetchResultsState,
  GlobalFetchOptionsAtom,
  itemsForFetchResults,
  lastFetchedAt,
} from "./atoms";
import {
  addRefsToFetchResults,
  setFetchedAt,
  setFetchResults,
} from "./actions";
import { TaskStoreAtom } from "@state/tasks";

export const useLazyFetchResults = <T extends EntityType = EntityType>(
  queryKey: string,
  type: T,
  filter: FilterQuery<EntityForType<T>>,
  opts?: FetchOptions
): EntityForType<T>[] => {
  const globalOpts = useRecoilValue(GlobalFetchOptionsAtom);
  const workspaceId = useActiveWorkspaceId();
  const hashed = useMemo(
    () => hashable({ key: queryKey, type }),
    [queryKey, type]
  );
  const onLoaded = useOnFetchResults(queryKey, type);
  const fetchedAt = useRecoilValue(lastFetchedAt(queryKey));
  const items = useRecoilValue(itemsForFetchResults(hashed));

  useAsyncEffect(async () => {
    if (opts?.fetch !== false) {
      const { changed, all } = await api.getOptimizedForFilter(
        { type, scope: workspaceId },
        filter,
        {
          archived: opts?.archived ?? globalOpts?.archived,
          since: opts?.since || fetchedAt,
          templates: opts?.templates,
        }
      );
      onLoaded(changed, all);
    }
  }, [globalOpts.archived, queryKey, type, filter]);

  return items as EntityForType<T>[];
};

export function useOnFetchResults(
  key: ID,
  type: Maybe<EntityType>,
  Atom: Fn<string, RecoilState<Maybe<FetchResultsState>>> = FetchResultsAtom
) {
  const Store = useMemo(
    () =>
      when(type, getStore) ||
      (TaskStoreAtom as RecoilState<StoreState<api.Entity>>),
    [type]
  );
  const setStore = useSetRecoilState(Store);
  const [results, setResults] = useRecoilState(Atom(key || ""));

  return useCallback(
    async (items: Entity[], ids?: string[]) => {
      if (items.length) {
        setStore(mergeItems(items));
      }

      const itemIds = ids || pickIds(items) || [];

      if (!isEqual(results?.ids, itemIds)) {
        // When the all IDs are different, we need to update the results
        setResults(setFetchResults(itemIds, now()));
      } else if (!!items.length || !results?.fetchedAt) {
        // Only bump the fetchedAt if we have new items or if we don't have a fetchedAt
        // Trying to prevent unnecessary re-renders
        setResults(setFetchedAt(now()));
      }
    },
    [results?.ids, type]
  );
}

export function useAddToFetchResults(key: ID) {
  const setResults = useSetRecoilState(FetchResultsAtom(key));

  return useCallback(
    async (ts: OneOrMany<Ref>) => {
      setResults(addRefsToFetchResults(ensureMany(ts)));
    },
    [setResults]
  );
}
