import { find, map, omit, reduce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  HasLocation,
  PropertyDef,
  Ref,
  RelationRef,
  RichText,
} from "@api";

import { useLazyProperties } from "@state/databases";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useEntityLabels } from "@state/settings";
import {
  useCreateFromObject,
  useCreateFromTemplate,
  useUpdateFromObject,
} from "@state/generic";

import { fallback, Fn } from "@utils/fn";
import { Maybe, maybeMap, when, whenNotEmpty } from "@utils/maybe";
import { OneOrMany, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import {
  extractMentions,
  isEmpty,
  toHtml,
  trimTrailingMentions,
} from "@utils/rich-text";
import { maybeTypeFromId } from "@utils/id";
import { ifDo_ } from "@utils/logic";
import { set } from "@utils/object";
import { useShortcut } from "@utils/event";
import { ComponentOrNode } from "@utils/react";
import { extractLinks } from "@utils/link";
import { toBaseScope } from "@utils/scope";
import { toPlainText } from "@utils/html";

import { Icon, KeyArrowDown, KeyEnter, PlusAlt, SpinnerIcon } from "@ui/icon";
import { Button } from "@ui/button";
import { Text } from "@ui/text";
import { FillSpace, HStack, SpaceBetween } from "./flex";
import { TextBox } from "@ui/rich-text";
import { GlobalEntitySelect, LocationSelect, TemplateSelect } from "@ui/select";
import { usePageId } from "@ui/app-page";

import { formatHtml } from "./rich-text/embed-extension";

import styles from "./add-entity-input.module.css";

export type Props = {
  source: DatabaseID;
  defaults?: Partial<Entity>;
  onAdded?: Fn<OneOrMany<Ref>, void>;
  canLink?: boolean;
  canLinkTemplates?: boolean;
  canUseTemplates?: boolean;
  showLocation?: boolean;
  placeholder?: string;
  icon?: ComponentOrNode;
  size?: "small" | "default" | "medium";
  autoFocus?: boolean;
  children?: string;
  className?: string;
};

const cleanPasted = (line: string) =>
  line.replace(/^(\- \[x\]|\[ \]|\[x\]|\- \[ \]|\- \[|\-)/gi, "")?.trim();

// Splits input html on either a new line, or all the top level tags, so it should not split nested tags, just the top level ones
// and returns valid html lines
const splitHtmlLines = (html: string) => {
  var el = document.createElement("div");
  el.innerHTML = html;
  return map(el.children, (c) => c.outerHTML);
};

const mentionsToChanges = (mentioned: RelationRef[], props: PropertyDef[]) => {
  return reduce(
    mentioned || [],
    (defs, m) => {
      const mentionType = when(m?.id, maybeTypeFromId);
      const firstRelationProp = fallback(
        // If a person, use default asigned fields
        ifDo_(mentionType === "person", () =>
          find(props, (f) => ["assigned", "owner"]?.includes(f.field))
        ),

        // Else find the first prop with this relation type
        () =>
          find(
            props,
            (p) =>
              ["relation", "relations"]?.includes(p.type) &&
              !!m?.id &&
              p.options?.references === mentionType
          )
      );

      if (!firstRelationProp || !m) {
        return defs;
      }

      return set(defs, {
        [firstRelationProp.field]:
          firstRelationProp.type === "relation" ? { id: m.id } : [{ id: m.id }],
      });
    },
    {} as Partial<Omit<Entity, "source">>
  );
};

export const AddEntityInput = ({
  source: _source,
  placeholder,
  onAdded,
  icon,
  size,
  defaults,
  autoFocus = false,
  canLink: _canLink = true,
  canUseTemplates = true,
  canLinkTemplates = false,
  showLocation = false,
  children,
  className,
}: Props) => {
  const pageId = usePageId();
  const workspaceId = useActiveWorkspaceId();
  const me = useCurrentUser();
  const [inputFocused, setInputFocused] = useState(autoFocus);
  const [inputValue, setInputValue] = useState<RichText>({});
  const [templateSelecting, setTemplateSelecting] = useState(false);
  const [location, setLocation] = useState<string>();
  const source = useMemo(
    () =>
      location
        ? {
            ...(defaults?.source || _source),
            scope: location,
          }
        : defaults?.source || _source,
    [location, _source, defaults?.source]
  );

  const canLink = useMemo(
    () => _canLink && source.scope !== toBaseScope(source.scope),
    [_canLink, source?.scope]
  );

  const [forceShow, setForceShow] = useState(false);
  const showInput = useMemo(
    () => forceShow || size !== "small" || inputFocused || !isEmpty(inputValue),
    [inputValue, inputFocused, forceShow, size]
  );
  const props = useLazyProperties(source);
  const type = useMemo(() => source?.type, [source]);
  const toTypeLabel = useEntityLabels(source?.scope, { case: "lower" });

  const create = useCreateFromObject(source.type, source.scope, pageId);
  const update = useUpdateFromObject(source, pageId);

  const inputEmpty = useMemo(() => isEmpty(inputValue), [inputValue]);
  const addButtonPrimary = useMemo(
    () => !inputFocused && !inputEmpty,
    [inputValue, inputFocused]
  );

  const handleLink = useCallback(
    (ref: Ref) => {
      // Apply defaults passed in as updates to the item being linked
      // Ignore location/source fields however
      update(ref, omit(defaults, "source", "location"));
      onAdded?.(ref);
      setInputValue({});
    },
    [onAdded]
  );

  const { create: createTemplate, loading } = useCreateFromTemplate(
    source,
    handleLink
  );

  // Would b nice to use this for templates too so same behavior for both
  const handleSubmit = useCallback(
    (rt: RichText, template?: Ref) => {
      const cleaned = maybeMap(
        splitHtmlLines(toHtml(rt)),
        (l): Maybe<Partial<Entity>> => {
          const mentioned = extractMentions({ html: l });
          const links = extractLinks(l);

          const cleaned =
            cleanPasted(trimTrailingMentions(toPlainText(l))) || undefined;

          return when(
            cleaned,
            (title) =>
              ({
                ...defaults,

                source: source,
                location: source.scope,

                ...mentionsToChanges(mentioned, props),

                // If body is supported, the links will be embedded in the body
                ...whenNotEmpty(links, (ls) => ({
                  body: { html: map(ls, formatHtml)?.join("") },
                })),

                title,
              } as Partial<Entity>)
          );
        }
      );

      setInputValue({});

      if (template) {
        map(whenEmpty(cleaned, [{}]), (overrides) =>
          createTemplate?.(template, {
            overrides: { [template.id]: overrides },
          })
        );
      } else if (cleaned.length) {
        const saved = create?.(cleaned);
        onAdded?.(saved || []);
      }
    },
    [inputValue, createTemplate, defaults, onAdded, props, source]
  );

  useShortcut(
    "ArrowDown",
    [
      (e) => inputFocused && !e.defaultPrevented,
      () => setTemplateSelecting(true),
    ],
    [inputFocused, inputEmpty]
  );

  // Enter to submit, but not when focused (as the text input will handle that)
  useShortcut(
    "Enter",
    [
      (e) => !inputEmpty && !inputFocused && !e.defaultPrevented,
      () => handleSubmit(inputValue),
    ],
    [inputFocused, inputEmpty, handleSubmit]
  );

  // Whenever passed in location changes then recalculate
  useEffect(
    () =>
      setLocation(
        (defaults as Maybe<HasLocation>)?.location ||
          _source?.scope ||
          defaults?.source?.scope ||
          me.id
      ),
    [defaults, _source, me]
  );

  return (
    <SpaceBetween
      gap={0}
      className={cx(styles.container, className)}
      fit="container"
    >
      {!showInput && (
        <Button
          inset
          subtle
          size="small"
          icon={loading ? SpinnerIcon : icon || PlusAlt}
          onClick={() => setForceShow(true)}
        />
      )}

      {showInput && (
        <>
          {!showLocation || isEmpty(inputValue) ? (
            <Icon icon={loading ? SpinnerIcon : icon || PlusAlt} />
          ) : (
            <div className={styles.insetRight}>
              <LocationSelect
                onChange={(s) => setLocation(s)}
                source={undefined}
                location={location}
                variant="compact"
                showCaret={false}
                showOpen={false}
              />
            </div>
          )}
          <FillSpace>
            <TextBox
              key="add-tasks-input"
              className={cx(styles.input, size === "medium" && styles.medium)}
              updateOn="change"
              placeholder={
                children ||
                placeholder ||
                `New ${when(source?.type, toTypeLabel) || "item"}`
              }
              focus={inputFocused || (showInput && size === "small")}
              onChanged={setInputValue}
              text={inputValue}
              submitOnEnter={true}
              onFocus={() => {
                setInputFocused(true);
                // Clear out the force show
                setForceShow(false);
              }}
              onBlur={() => setInputFocused(false)}
              onEnter={handleSubmit}
            />
          </FillSpace>
        </>
      )}

      <HStack gap={0}>
        <Button
          subtle
          size="small"
          iconRight={KeyEnter}
          variant={!addButtonPrimary ? "secondary" : "primary"}
          disabled={inputEmpty}
          onClick={() => handleSubmit(inputValue)}
          className={cx(inputEmpty && styles.invisible)}
        >
          <Text
            className={cx(addButtonPrimary && styles.inherit)}
            subtle={!addButtonPrimary}
          >
            Add
          </Text>
        </Button>

        {!!type &&
          !!createTemplate &&
          canUseTemplates &&
          (!showInput || size !== "small") && (
            <TemplateSelect
              type={type}
              allowed={[type]}
              open={templateSelecting}
              setOpen={setTemplateSelecting}
              scope={source?.scope || workspaceId}
              allowNew={false}
              value={undefined}
              onChange={(r) => r && handleSubmit?.(inputValue, r)}
              closeOnSelect={true}
              portal={true}
            >
              <Button
                iconRight={!inputEmpty ? KeyArrowDown : undefined}
                subtle
                size="small"
              >
                <Text subtle>
                  {!inputEmpty ? "With template" : "Use template"}
                </Text>
              </Button>
            </TemplateSelect>
          )}

        {!!type &&
          inputEmpty &&
          canLink &&
          (!showInput || size !== "small") && (
            <GlobalEntitySelect
              scope={source?.scope}
              type={type}
              allowed={[type]}
              value={undefined}
              onChange={(v) => v && handleLink(v)}
              options={{ templates: canLinkTemplates }}
              placeholder={"Link existing"}
              closeOnSelect={true}
              portal={true}
            >
              <Button subtle size="small">
                <Text subtle>Link existing</Text>
              </Button>
            </GlobalEntitySelect>
          )}
      </HStack>
    </SpaceBetween>
  );
};
