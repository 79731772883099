import {
  Connection,
  Edge,
  Node,
  MarkerType,
  NodeProps,
  Position,
} from "reactflow";
import { useCallback, useEffect, useMemo, useState } from "react";
import { find, uniq } from "lodash";

import { Entity, EntityType, ID, isTeam } from "@api";

import { toLabel, toLabel as toTeamLabel, useLazyAllTeams } from "@state/teams";
import { useLazyEntity, useQueueUpdates } from "@state/generic";

import { treeLayout, useLayoutStrategy } from "@utils/chart";
import { maybeMap, when } from "@utils/maybe";
import { maybeLookupById, overlaps } from "@utils/array";
import { Fn } from "@utils/fn";
import { toPropertyValueRef, toRef } from "@utils/property-refs";
import { mapAll } from "@utils/promise";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { showError } from "@ui/notifications";
import { Flow, Handle, withFlowProvider } from "@ui/flow";
import { Text } from "@ui/text";
import { useGoTo } from "@utils/navigation";
import { Card } from "@ui/card";
import { SpaceBetween, VStack } from "@ui/flex";

import styles from "./team-structure-flow.module.css";
import { Icon, PersonIcon, TeamIcon } from "@ui/icon";
import { cx } from "@utils/class-names";

interface Props {
  whitelist?: string[];
  className?: string;
  hideUnconnected?: boolean;
  editing?: boolean;
  showAll?: boolean;
  onSelected?: Fn<EntityType, void>;
}

export const TeamStructureFlow = withFlowProvider(
  ({
    className,
    editing = false,
    whitelist,
    hideUnconnected = true,
    onSelected,
  }: Props) => {
    const pageId = usePageId();
    const teams = useLazyAllTeams();
    const mutate = useQueueUpdates(pageId);
    const goTo = useGoTo();
    const getTeam = useMemo(() => maybeLookupById(teams), [teams]);

    const strat = useMemo(
      () =>
        treeLayout(
          { width: 200, height: 80, vSpace: 8 },
          { orientation: "vertical" }
        ),
      []
    );
    const [selected, setSelected] = useState<string[]>([]);

    const { nodes, edges, setData, ...flowProps } = useLayoutStrategy<{
      label: string;
      entity?: Entity;
      selected?: boolean;
    }>(strat);

    const handleConnect = useCallback(
      async (edge: Edge | Connection) => {
        const team = when(edge.target, getTeam);
        const parent = when(edge.source, getTeam);

        if (!team || !parent) {
          showError("Could not find teams to connect.");
          return;
        }

        mutate(
          asUpdate(
            team,
            asMutation({ field: "parent", type: "relation" }, toRef(parent))
          )
        );
      },
      [teams, setData]
    );

    const handleDelete = useCallback(
      async (connection: Edge[]) => {
        await mapAll(connection, async (edge) => {
          const team = when(edge.target, getTeam);
          const parent = when(edge.source, getTeam);

          if (!team || !parent || team.parent?.id !== parent.id) {
            showError("Could not find teams to disconnect.");
            return;
          }

          mutate(
            asUpdate(
              team,
              asMutation({ field: "parent", type: "relation" }, undefined)
            )
          );
        });
      },
      [teams, setData]
    );

    const onNodeClicked = useCallback(
      (event: React.MouseEvent, node: Node) => {
        if (!editing) {
          return when(node.data.entity, goTo);
        }

        if (event?.defaultPrevented) {
          return;
        }

        onSelected?.(node?.data.entity);
        setSelected((s) =>
          s?.includes(node?.data.entity) ? [] : uniq([node.id])
        );
      },
      [editing]
    );

    const nodeTypes = useMemo(() => ({ team: TeamNode(editing) }), [editing]);

    useEffect(() => {
      setData({
        nodes: [
          ...maybeMap(teams, (team, i) =>
            hideUnconnected &&
            (team.parent ||
              team.subTeams?.length ||
              whitelist?.includes(team?.id))
              ? {
                  id: team.id,
                  position: find(nodes, { id: team.id })?.position || {
                    x: 0,
                    y: 0,
                  },
                  draggable: !!editing,
                  data: { label: toTeamLabel(team), entity: team },
                  type: "team",
                }
              : undefined
          ),
        ],

        edges: maybeMap(teams, (team) => {
          const parentValueRef = toPropertyValueRef(team, {
            field: "parent",
            type: "relation",
          });
          const parent = parentValueRef?.value.relation;

          if (!parent || !parent.id) {
            return undefined;
          }

          return {
            id: `${team.id}-${parent.id}`,
            source: parent.id,
            target: team.id,
            type: "step",
            data: {
              selected: overlaps(selected, [parent.id, team.id]),
              editable: editing,
              label: "",
            },
            markerEnd: {
              type: MarkerType.Arrow,
              color: "var(--color-primary)",
            },
          };
        }),
      });
    }, [editing, teams, selected, whitelist]);

    return (
      <Flow
        className={cx(className, styles.flow, editing && styles.editing)}
        nodes={nodes}
        edges={edges}
        {...flowProps}
        nodeTypes={nodeTypes}
        onConnect={editing ? handleConnect : undefined}
        onEdgesDelete={editing ? handleDelete : undefined}
        onNodeClick={onNodeClicked}
        // onEdgeClick={() => setSelected([])}
      />
    );
  }
);

export const TeamNode =
  (editable: boolean) =>
  ({
    data: { label, entity, selected, parentId, direction },
  }: NodeProps<{
    label: string;
    entity: Entity;
    parentId?: ID;
    editable?: boolean;
    selected?: boolean;
    direction: "horizontal" | "vertical";
  }>) => {
    const team = isTeam(entity) ? entity : undefined;
    const owner = useLazyEntity<"person">(team?.owner?.id, false);

    if (!team) {
      return <></>;
    }

    const pushTo = useGoTo();
    return (
      <Card className={styles.team} onDoubleClick={() => pushTo(entity)}>
        <Handle
          className={cx(styles.handle, !editable && styles.hidden)}
          type="target"
          position={Position.Top}
        />

        <VStack gap={4}>
          <SpaceBetween>
            <Icon icon={<TeamIcon team={team} />} />
            {!!owner && <Icon icon={<PersonIcon person={owner} />} />}
          </SpaceBetween>

          <Text className={styles.text}>{toLabel(team)}</Text>
        </VStack>

        <Handle
          className={cx(styles.handle, !editable && styles.hidden)}
          type="source"
          position={Position.Bottom}
        />
      </Card>
    );
  };
