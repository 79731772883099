import { groupBy, map, filter as loFilter, isString, find } from "lodash";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Portal } from "@radix-ui/react-portal";

import { ID, Person, Team, toTitleOrName, Workspace } from "@api";

import { toShortLabel, useLazyAllTeams } from "@state/teams";
import { useActiveSpace, useSetSpace } from "@state/spaces";
import { useActiveWorkspaceId, useCurrentWorkspace } from "@state/workspace";
import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { maybeLookup, next } from "@utils/array";
import { containsRef } from "@utils/relation-ref";
import { Maybe, when } from "@utils/maybe";
import { Fn } from "@utils/fn";
import {
  Handleable,
  respectHandled,
  useShortcut,
  useWindowEvent,
  withHandle,
} from "@utils/event";

import { Button, Props as ButtonProps } from "@ui/button";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowVertical,
  EyeSlash,
  Icon,
  iconFromString,
  ImageIcon,
  PlusAlt,
  SquareIcon,
  TeamIcon,
} from "@ui/icon";
import { Text, TextXLarge } from "@ui/text";
import { TabBar } from "@ui/tab-bar";
import { WorkspaceSelect } from "@ui/workspace-select";

import styles from "./spaces-overlay.module.css";
import { switchEnum } from "@utils/logic";
import { useStickyState } from "@utils/hooks";
import { TeamCreateDialog } from "@ui/engine/team";
import { T } from "lodash/fp";

export const SpacesOverlay = ({
  onChanged,
  onClose,
}: {
  onChanged: Fn<ID, void>;
  onClose: Fn<void, void>;
}) => {
  const me = useMe();
  const ref = useRef<HTMLDivElement>(null);
  const wID = useActiveWorkspaceId();
  const [addingTeam, setAddingTeam] = useState<boolean | string>(false);
  const [filter, setFilter] = useStickyState<"all" | "me">(
    "all",
    "spaces-filter"
  );
  const workspace = useCurrentWorkspace();
  const allTeams = useLazyAllTeams();
  const filteredTeams = useMemo(
    () =>
      filter === "me"
        ? loFilter(allTeams, (t) => containsRef(t.people, me))
        : allTeams,
    [allTeams, filter]
  );
  const teams = useMemo(() => {
    const exists = maybeLookup(filteredTeams, (t) => t.id);
    return groupBy(
      filteredTeams,
      (team) =>
        when(team.parent?.id, (id) => (exists(id) ? id : undefined)) || "all"
    );
  }, [filteredTeams, filter]);
  const space = useActiveSpace();
  const _setSpace = useSetSpace();
  const [selected, setSelected] = useState<Maybe<string>>(space.id);

  const animateOut = useCallback(
    (id: string) => {
      onChanged(id);
      ref.current?.classList.remove(styles.in);
      // Wait for animations to finish
      setTimeout(() => onClose(), 240);
    },
    [onChanged]
  );

  const setSpace = useCallback((id: ID) => {
    _setSpace(id);
    setSelected(id);
    animateOut(id);
  }, []);

  const addingDefaults = useMemo(
    () =>
      !addingTeam
        ? undefined
        : isString(addingTeam)
        ? {
            parent: { id: addingTeam },
            color: find(allTeams, (t) => t.id === addingTeam)?.color,
          }
        : {},
    [addingTeam, allTeams]
  );

  useLayoutEffect(() => {
    setTimeout(() => ref.current?.classList.add(styles.in), 10);
  }, [ref]);

  useWindowEvent(
    "keydown",
    (e) => {
      if (addingTeam) {
        return;
      }

      if (e.key.length === 1 && e.key.match(/[a-zA-Z]/i)) {
        // if is alphabet
        const matching = loFilter(
          workspace ? [workspace, ...filteredTeams] : filteredTeams,
          (t) => toTitleOrName(t).startsWith(e.key.toUpperCase())
        );

        // Only match
        if (matching.length === 1) {
          setSpace(matching[0].id);
        } else if (matching.length) {
          const n = next(
            map(matching, (m) => m.id),
            selected,
            true
          );
          setSelected(n);
        }
      }
    },
    true,
    [filteredTeams, selected, addingTeam]
  );

  useShortcut(
    "Enter",
    [() => !addingTeam, () => setSpace(selected || space.id)],
    [selected, addingTeam]
  );

  return (
    <Portal>
      <div
        ref={ref}
        className={cx(styles.container, styles.animation)}
        onClick={respectHandled(() => !addingTeam && animateOut(space.id))}
      >
        {addingDefaults && (
          <div onClick={withHandle(() => {})}>
            <TeamCreateDialog
              defaults={addingDefaults}
              onCancel={() => setAddingTeam(false)}
              onSaved={(t) => setSpace(t.id)}
            />
          </div>
        )}
        <div className={styles.workspaceBar} onClick={withHandle(() => {})}>
          <WorkspaceSelect iconRight={false}>
            <SpaceBetween gap={6}>
              {when(iconFromString(workspace?.icon), (i) => (
                <Icon icon={i} size="small" />
              ))}

              <Text bold className={styles.text}>
                {workspace?.name}
              </Text>
              <Icon
                icon={ArrowVertical}
                size="xsmall"
                className={styles.icon}
              />
            </SpaceBetween>
          </WorkspaceSelect>
        </div>

        <SpaceBetween className={styles.fixedTeams} align="flex-start">
          <SpaceButton
            onClick={withHandle(() => setSpace(wID))}
            entity={workspace}
            icon={
              <SquareIcon className={styles.largeIcon} color="gray_5">
                {when(workspace?.icon, (i) => <ImageIcon url={i} />) ||
                  workspace?.name?.substring(0, 1)?.toUpperCase()}
              </SquareIcon>
            }
            selected={selected === wID}
          />

          <div onClick={withHandle(() => {})}>
            <TabBar
              active={filter}
              onActiveChanged={(v) => setFilter(v as "all" | "me")}
              className={styles.tabBar}
              itemClassName={styles.tabBarItem}
              options={[
                { id: "all", title: "All Teams" },
                { id: "me", title: "My Teams" },
              ]}
            />
          </div>

          <SpaceButton
            selected={selected === me.id}
            entity={me}
            onClick={withHandle(() => setSpace(me.id))}
            icon={
              <SquareIcon color="gray_5" className={cx(styles.largeIcon)}>
                <Icon icon={EyeSlash} size="medium" />
              </SquareIcon>
            }
          />
        </SpaceBetween>

        <div className={styles.teams}>
          <HStack
            wrap
            fit="container"
            align="flex-start"
            gap={40}
            justify="center"
          >
            {map(teams["all"], (team) => (
              <TeamStack
                key={team.id}
                team={team}
                subteams={teams[team.id]}
                selected={selected}
                onSelected={(id) => setSpace(id)}
                onAddNew={(id) => setAddingTeam(id)}
              />
            ))}
          </HStack>
        </div>

        <div className={styles.bottomBar}>
          <Icon
            onClick={withHandle(() => setAddingTeam(true))}
            size="large"
            className={cx(styles.icon, styles.largeIcon, styles.addIcon)}
            icon={
              <SquareIcon color="gray_5">
                <Icon icon={PlusAlt} />
              </SquareIcon>
            }
          />
        </div>
      </div>
    </Portal>
  );
};

const TeamStack = ({
  team,
  subteams,
  selected,
  onSelected,
  onAddNew,
}: {
  team: Team;
  onSelected?: Fn<ID, void>;
  onAddNew: Fn<ID, void>;
  selected?: string;
  subteams: Maybe<Team[]>;
}) => {
  return (
    <VStack align="center" fit="content" className={styles.teamStack}>
      <SpaceButton
        entity={team}
        selected={selected === team.id}
        onClick={withHandle(() => onSelected?.(team.id))}
        icon={<TeamIcon team={team} className={styles.largeIcon} />}
      />

      <VStack fit="container" align="center" gap={0}>
        {map(subteams, (sub) => (
          <SpaceButton
            key={sub.id}
            size="medium"
            onClick={withHandle(() => onSelected?.(sub.id))}
            entity={sub}
            selected={selected === sub.id}
            icon={<TeamIcon team={sub} className={styles.mediumIcon} />}
          />
        ))}

        <Icon
          onClick={withHandle(() => onAddNew(team.id))}
          className={cx(styles.icon, styles.mediumIcon, styles.addIcon)}
          size="xlarge"
          icon={
            <SquareIcon color="gray_5">
              <Icon icon={PlusAlt} />
            </SquareIcon>
          }
        />
      </VStack>
    </VStack>
  );
};

const SpaceButton = ({
  entity,
  selected,
  onClick,
  icon,
  size = "large",
  className,
}: {
  entity?: Maybe<Person | Team | Workspace>;
  size?: "medium" | "large";
  icon: Exclude<ButtonProps["icon"], undefined>;
  selected?: boolean;
  className?: string;
  onClick: Fn<Handleable, void>;
}) => (
  <Button
    subtle
    className={cx(styles.button, styles.team, styles[size], className)}
    onClick={onClick}
  >
    <VStack align="center">
      <Icon
        size={size === "large" ? "xxlarge" : "xlarge"}
        className={cx(
          styles.icon,
          size === "large" && styles.largeIcon,
          size === "medium" && styles.mediumIcon,
          selected && styles.selected
        )}
        icon={icon}
      />
      {size === "large" && (
        <TextXLarge bold className={styles.text}>
          {switchEnum(entity?.source.type || "", {
            team: () => toShortLabel(entity as Team),
            workspace: () => "All Teams",
            person: () => "Private",
            else: () => "",
          })}
        </TextXLarge>
      )}
      {entity && size === "medium" && (
        <Text bold className={styles.text}>
          {toShortLabel(entity as Team)}
        </Text>
      )}
    </VStack>
  </Button>
);
