import useSound from "use-sound";
import { differenceInMinutes } from "date-fns";
import { findIndex, first, last, map, orderBy, some, sum } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Meeting,
  Entity,
  Status,
  Agenda,
  Update,
  Ref,
  PropertyMutation,
  RichText,
} from "@api";

import { useEditInAppCommands } from "@state/app";
import {
  useApplyTemplate,
  useCreateFromObject,
  useLazyEntities,
  useNestedSource,
  useQueueUpdates,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef, useLazyPropertyValue } from "@state/databases";
import { isHost, toDurationMins } from "@state/meetings";
import { useCurrentUser } from "@state/workspace";
import { useReorderAgendas } from "@state/agendas";
import { useMeetingAgendas } from "@state/meetings";

import { useOverridableState, useStickyState } from "@utils/hooks";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { asTimestamp, formatDay } from "@utils/date";
import {
  asAppendMutation,
  asDeleteUpdate,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import {
  length,
  ensureArray,
  ensureMany,
  isLastIndex,
  OneOrMany,
  justOne,
} from "@utils/array";
import { toRef } from "@utils/property-refs";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import {
  handle,
  respectHandled,
  withHandle,
  withHardHandle,
} from "@utils/event";
import { useGoTo } from "@utils/navigation";
import { explode } from "@utils/confetti";
import { asNumber } from "@utils/number";
import { fromPointDate, now, usePointDate } from "@utils/date-fp";
import { isEmpty } from "@utils/rich-text";
import { useTick } from "@utils/time";
import { abs } from "@utils/math";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  AngleDownIcon,
  AngleRightIcon,
  ArrowRight,
  Icon,
  Magic,
  PlusAlt,
  StatusLive,
  TrashAlt,
} from "@ui/icon";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { Button } from "@ui/button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { InlinePropertyLabel } from "@ui/property-label";
import { StatusTag } from "@ui/tag";
import { LabelledValue } from "@ui/property-value";
import { PeopleStack } from "@ui/people-stack";
import { CollapsibleSection } from "@ui/collapsible-section";
import { ReadonlyDocument, ReadonlyPlainText } from "@ui/rich-text";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { EditableText } from "@ui/editable-text";
import { ColoredSection, Section } from "@ui/section";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { AddEntityInput } from "@ui/add-entity-input";
import { EntityList } from "@ui/entity-list";
import { ColorSelect } from "@ui/select/color";
import { DropTarget, OnReorder, useItemDragDrop } from "@ui/entity-drag-drop";
import { DropHighlight } from "@ui/drop-highlight";
import { BackgroundColor } from "@ui/background-color";
import { PersonMultiSelect, TemplateSelect } from "@ui/select";
import { DurationPicker } from "@ui/time-picker";
import { AgendaSelect } from "@ui/select/agenda";
import { Dialog } from "@ui/dialog";
import { RealTimeDocumentEditor } from "@ui/real-time-field";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { showSuccess } from "@ui/notifications";

import { PaneOpts } from "../types";
import { EndMeetingDialog } from "./end-meeting-dialog";
import { MeetingDivider, TimeStack } from "./comps";
import { ScheduleButton } from "../schedule";

import styles from "./styles.module.css";

export const MeetingPane = ({ id, item: meeting }: PaneOpts<Meeting>) => {
  const me = useCurrentUser();
  const mutate = useUpdateEntity(meeting.id, meeting.source.scope);
  const hosting = isHost(meeting, me);
  const status = useLazyPropertyValue(meeting, {
    field: "status",
    type: "status",
  });
  const sectionProps = useMemo(
    () => ({ meeting, status: status?.value.status, mutate }),
    [meeting, mutate, status?.value.status]
  );

  return (
    <Sheet size="primary">
      {!!meeting.template && <TemplateBanner />}

      <MeetingHeader {...sectionProps} />

      <Centered stack="vertical" gap={30}>
        <MeetingPurposeSection {...sectionProps} />
        {status.value.status?.group === "done" && (
          <>
            <MeetingSummarySection {...sectionProps} />
            <MeetingNotesSection {...sectionProps} />
          </>
        )}
        {!hosting && status.value.status?.group === "in-progress" && (
          <EnableSoundsDialog />
        )}
        <MeetingPeopleSection {...sectionProps} />
        <MeetingAgendaSection {...sectionProps} />
      </Centered>
    </Sheet>
  );
};

interface HeaderProps {
  meeting: Meeting;
  status?: Status;
  mutate: Fn<OneOrMany<PropertyMutation<Meeting>>, void>;
}

const MeetingHeader = ({ meeting, status }: HeaderProps) => {
  const goTo = useGoTo();
  const pageId = usePageId();
  const mutate = useUpdateEntity(meeting.id, pageId);
  const editInAppCommands = useEditInAppCommands();

  const startProp = useLazyPropertyDef(meeting.source, {
    field: "start",
    type: "date",
  });

  const hostProp = useLazyPropertyDef(meeting.source, {
    field: "owner",
    type: "relation",
  });

  const durationProp = useLazyPropertyDef(meeting.source, {
    field: "duration",
    type: "number",
  });

  if (!meeting) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={0}>
      <Container>
        <EntityHeaderBar entity={meeting} />
      </Container>

      <Centered>
        <VStack gap={20}>
          <SpaceBetween direction="horizontal" align="stretch" gap={12}>
            <MeetingDivider entity={meeting} />

            <Container gap={6} padding="none" inset="bottom" stack="vertical">
              <StatusTag
                showIcon={status?.group === "in-progress"}
                status={status}
              />

              <VStack gap={0}>
                <HStack align="baseline">
                  <EditableHeading
                    key={meeting.id}
                    text={meeting.name || ""}
                    placeholder="Meeting name"
                    onChange={(text) => {
                      when(text, (i) =>
                        mutate(asMutation({ field: "name", type: "text" }, i))
                      );
                    }}
                  />
                  {meeting.start && !meeting.template && (
                    <Button
                      subtle
                      size="tiny"
                      onClick={() => editInAppCommands(startProp, meeting)}
                    >
                      <Text subtle>
                        {usePointDate(meeting.start, formatDay)}
                      </Text>
                    </Button>
                  )}

                  {!!(meeting.refs?.repeat || meeting.refs?.schedule)
                    ?.length && (
                    <ScheduleButton
                      size="tiny"
                      subtle={true}
                      onClick={() =>
                        when(
                          first(meeting.refs?.repeat || meeting.refs?.schedule),
                          goTo
                        )
                      }
                      schedule={first(
                        meeting.refs?.repeat || meeting.refs?.schedule
                      )}
                    />
                  )}
                </HStack>

                <HStack gap={0} align="baseline">
                  {!meeting.template && (
                    <Button
                      subtle
                      size="tiny"
                      onClick={() => editInAppCommands(startProp, meeting)}
                    >
                      <HStack gap={4}>
                        <TimeStack entity={meeting} separator={"-"} />
                      </HStack>
                    </Button>
                  )}

                  <Button
                    subtle
                    size="tiny"
                    onClick={() => editInAppCommands(durationProp, meeting)}
                  >
                    <TextSmall subtle>({meeting.duration} mins)</TextSmall>
                  </Button>
                </HStack>
              </VStack>
            </Container>

            <HStack>
              <LabelledValue label="Host">
                <Container
                  size="half"
                  padding="vertical"
                  onClick={() => editInAppCommands(hostProp, meeting)}
                >
                  <PeopleStack
                    people={when(meeting.owner, ensureArray) || []}
                    size="xlarge"
                  />
                </Container>
              </LabelledValue>
            </HStack>
          </SpaceBetween>

          {!meeting.template && (
            <HStack fit="container" gap={4}>
              <WorkflowActions entity={meeting} />
            </HStack>
          )}
        </VStack>
      </Centered>
    </VStack>
  );
};

export const DayTimePropertyLabel = <E extends Entity>({
  entity,
}: {
  entity: E;
}) => {
  return (
    <HStack gap={10}>
      <InlinePropertyLabel
        entity={entity}
        prop={{ field: "start", type: "date" }}
        format="day"
        propTypeIcon={true}
      />
      <HStack gap={0}>
        <InlinePropertyLabel
          entity={entity}
          prop={{ field: "start", type: "date" }}
          format="time"
          propTypeIcon={false}
        />
        <Icon icon={ArrowRight} />
        <InlinePropertyLabel
          entity={entity}
          prop={{ field: "end", type: "date" }}
          format="time"
          propTypeIcon={false}
        />
      </HStack>
    </HStack>
  );
};

const MeetingPeopleSection = ({ meeting, status, mutate }: HeaderProps) => {
  return (
    <CollapsibleSection
      title="Attendees"
      labelSize="medium"
      actions={
        <PersonMultiSelect
          value={meeting.refs.people}
          onChange={(p) =>
            mutate(asMutation({ field: "refs.people", type: "relations" }, p))
          }
        >
          <Button subtle size="tiny">
            <Text subtle>Add/Remove</Text>
          </Button>
        </PersonMultiSelect>
      }
    >
      <HStack>
        <Container size="half" padding="vertical">
          <PeopleStack people={meeting.refs.people} size="xlarge" />
        </Container>
      </HStack>
    </CollapsibleSection>
  );
};

const MeetingPurposeSection = ({ meeting, mutate, status }: HeaderProps) => {
  const me = useCurrentUser();
  const purpose = meeting.purpose || "";

  if (!purpose && !isHost(meeting, me)) {
    return <></>;
  }

  return (
    <ColoredSection title="Purpose">
      <EditableText
        allowNewLines={true}
        placeholder="What is the purpose of this meeting?"
        text={purpose}
        onChange={(rt) =>
          mutate(asMutation({ field: "purpose", type: "text" }, rt))
        }
      />
    </ColoredSection>
  );
};

const MeetingSummarySection = ({ meeting, status }: HeaderProps) => {
  if (isEmpty(meeting.summary)) {
    return <></>;
  }

  return (
    <ColoredSection icon={Magic} title="Summary" color="purple_5">
      <ReadonlyPlainText text={meeting.summary} />
    </ColoredSection>
  );
};

const MeetingNotesSection = ({ meeting, status }: HeaderProps) => {
  const agendas = useMeetingAgendas(meeting);

  const hasNotes = useMemo(
    () => some(agendas, (a) => !isEmpty(a.notes)),
    [agendas]
  );

  if (!meeting.refs?.agendas?.length) {
    return <></>;
  }

  return (
    <ColoredSection>
      <VStack>
        {maybeMap(
          agendas,
          (agenda) =>
            !isEmpty(agenda.notes) && (
              <VStack gap={0} key={agenda.id}>
                <TextSmall subtle>{agenda.title}</TextSmall>
                <ReadonlyDocument textSize="default" content={agenda.notes} />
              </VStack>
            )
        )}
        {!hasNotes && <Text subtle>No notes taken in this meeting...</Text>}
      </VStack>
    </ColoredSection>
  );
};

const MeetingAgendaSection = ({ meeting, status }: HeaderProps) => {
  const pageId = usePageId();
  const agendaSource = useNestedSource(meeting, "agenda");
  const mutate = useQueueUpdates(pageId);
  const create = useCreateFromObject("agenda", agendaSource.scope);
  const { apply } = useApplyTemplate(meeting, () =>
    showSuccess("Template agenda added to meeting")
  );
  const agendas = useMeetingAgendas(meeting);
  const [focusLast, setFocusLast] = useState(false);
  const [ending, showEnd] = useState(false);
  const [allOpen, setAllOpen] = useState(false);
  const active = useMemo(
    () => justOne(meeting.refs?.current),
    [meeting.refs?.current]
  );
  const setActive = useCallback((id?: string) => {
    mutate([
      asUpdate(
        meeting,
        asMutation(
          { field: "refs.current", type: "relation" },
          id ? toRef(id) : undefined
        )
      ),
    ]);

    setFocusLast(false);
  }, []);

  const meetingTime = useMemo(() => toDurationMins(meeting) || 0, [meeting]);
  const agendaTime = useMemo(
    () => sum(map(agendas, (a) => asNumber(a.custom?.allocated))),
    [agendas]
  );
  const availableTime = useMemo(
    () => meetingTime - agendaTime,
    [agendas, meetingTime]
  );

  const handleReorder = useReorderAgendas(meeting, agendas);

  const handleAddAgenda = useCallback(
    (title?: string) => {
      const [agenda] =
        create?.([
          {
            title: title || "",
            color: "blue_5",
            body: { html: "" },
            refs: { meeting: [toRef(meeting)] },
            order: length(agendas) + 1,
          },
        ]) || [];

      // This is done serverside from above but we haven't solved how to get that data yet
      mutate([
        asUpdate(
          meeting,
          asAppendMutation({ field: "refs.agendas", type: "relations" }, [
            { id: agenda.id },
          ])
        ),
      ]);

      setFocusLast(true);
    },
    [length(agendas), create, mutate]
  );

  const handleCopyFrom = useCallback(
    (agendas: Maybe<Agenda[]>) => {
      if (!agendas?.length) return;

      const created = create?.(
        map(agendas, (a) => ({
          ...a,
          open: false,
          notes: undefined,
          stamps: {},
          refs: {
            ...a.refs,
            actions: [],
            meeting: [toRef(meeting)],
          },
          location: agendaSource.scope,
          source: agendaSource,
          id: undefined,
        }))
      );

      // Can remove when sorting out .sync on relations
      mutate([
        asUpdate(
          meeting,
          asAppendMutation(
            { field: "refs.agendas", type: "relations" },
            map(created, (a) => toRef(a))
          )
        ),
      ]);
    },
    [meeting]
  );

  const handleApplyTemplate = useCallback(
    (template: Ref) => apply(template),
    [apply]
  );

  const handleNext = useCallback(() => {
    const i = findIndex(agendas, (a) => a.id === active?.id);
    const next = agendas[i + 1]?.id;
    setActive(next);
    if (!next) {
      showEnd(true);
    }
  }, [active]);

  const handleDelete = useCallback(
    (agenda: Agenda) => {
      mutate([
        asDeleteUpdate(agenda),
        asUpdate(
          meeting,
          asAppendMutation(
            { field: "refs.agendas", type: "relations" },
            [{ id: agenda.id }],
            "remove"
          )
        ),
      ] as Update<Entity>[]);
    },
    [handleAddAgenda]
  );

  return (
    <CollapsibleSection
      title={
        <Button
          inset
          size="tiny"
          iconRight={allOpen ? AngleDownIcon : AngleRightIcon}
          subtle
          onClick={() => setAllOpen(!allOpen)}
        >
          <TextMedium>Agenda</TextMedium>
        </Button>
      }
      forceOpen={true}
      labelSize="medium"
      actions={
        <Button subtle disabled size="tiny">
          <Text subtle>{`${agendaTime || meetingTime} mins`}</Text>
        </Button>
      }
    >
      {ending && (
        <EndMeetingDialog
          meeting={meeting}
          onCancel={() => showEnd(false)}
          onComplete={() => showEnd(false)}
        />
      )}
      <VStack fit="container">
        {map(agendas, (agenda, i) => (
          <Container key={agenda.id} padding="none" inset="horizontal">
            <MeetingAgenda
              meeting={meeting}
              status={status}
              agenda={agenda}
              availableTime={availableTime}
              active={active?.id === agenda.id}
              focus={focusLast && isLastIndex(i, agendas)}
              forceOpen={allOpen || active?.id === agenda.id}
              onActive={() => setActive(agenda?.id)}
              onNext={handleNext}
              onDelete={handleDelete}
              onReorder={handleReorder}
            />
          </Container>
        ))}

        <Container fit="container" padding="none">
          <DropTarget type="agenda" position="after" item={last(agendas)}>
            <HStack gap={0}>
              <Button
                icon={PlusAlt}
                subtle
                size="small"
                onClick={() => handleAddAgenda()}
              >
                Add new
              </Button>

              <AgendaSelect
                scope={meeting.source.scope}
                onChange={handleCopyFrom}
              >
                <Button icon={PlusAlt} subtle size="small">
                  Copy from...
                </Button>
              </AgendaSelect>

              <TemplateSelect
                scope={meeting.source.scope}
                allowNew={false}
                type="meeting"
                hasFieldSet={{ field: "refs.agendas", type: "relations" }}
                allowed={["meeting"]}
                value={undefined}
                onChange={(r) => r && handleApplyTemplate(r)}
                closeOnSelect={true}
              >
                <Button icon={PlusAlt} subtle size="small">
                  Use template...
                </Button>
              </TemplateSelect>
            </HStack>
          </DropTarget>
        </Container>
      </VStack>
    </CollapsibleSection>
  );
};

interface AgendaProps {
  meeting: Meeting;
  agenda: Agenda;
  status: Maybe<Status>;
  active?: boolean;
  forceOpen?: boolean;
  focus?: boolean;
  availableTime?: number;
  onActive?: Fn<void, void>;
  onDelete?: Fn<Agenda, void>;
  onNext?: Fn<void, void>;
  onReorder?: OnReorder<Agenda>;
}

const MeetingAgenda = ({
  meeting,
  status,
  focus,
  availableTime,
  forceOpen,
  agenda,
  active,
  onDelete,
  onActive,
  onNext,
  onReorder,
}: AgendaProps) => {
  const me = useCurrentUser();
  const ref = useRef<HTMLDivElement>(null);

  const completed = !!agenda.stamps?.done;
  const running = status?.group === "in-progress";
  const hosting = isHost(meeting, me);

  const [_open, setOpen] = useState<boolean>(true);
  const [showActions, setShowActions] = useOverridableState(
    active || !!agenda.refs.actions?.length
  );
  const [showNotes, setShowNotes] = useOverridableState(
    active || !isEmpty(agenda.notes)
  );
  const nestedSource = useNestedSource(agenda, "action");
  const mutate = useUpdateEntity(agenda.id, agenda.source.scope);
  const _actions = useLazyEntities(agenda.refs.actions || []);
  const actions = useMemo(
    () => orderBy(_actions, (a) => asTimestamp(a.createdAt || now())),
    [_actions]
  );
  const open = forceOpen || _open;

  const agendaTime = useMemo(
    () => asNumber(agenda.custom?.allocated),
    [agenda.custom?.allocated]
  );
  const runningTime = useMemo(
    () =>
      running
        ? abs(
            differenceInMinutes(
              fromPointDate(agenda.stamps?.end || now()),
              fromPointDate(agenda.stamps?.start || now())
            )
          )
        : undefined,
    [running, agenda.stamps?.start, agenda.stamps?.end, useTick("1 second")]
  );

  const { opacity, dropping } = useItemDragDrop({
    item: agenda,
    onReorder: onReorder || (() => {}),
    ref,
  });
  const css = useMemo(() => ({ opacity }), [opacity]);

  const handleAddAction = useCallback(
    (action: OneOrMany<Ref>) => {
      // Double add to agenda because doesn't happen immediately due to not fetching arch isssue...
      mutate(
        asAppendMutation(
          { field: "refs.actions", type: "relations" },
          map(ensureMany(action), (a) => toRef(a))
        )
      );
    },
    [agenda]
  );

  const handleTitleBlur = useCallback(
    (t: Maybe<string>) => {
      if (!t?.trim()) {
        mutate(asMutation({ field: "title", type: "text" }, "To discuss"));
      }
    },
    [mutate]
  );

  const handleComplete = useCallback(() => {
    mutate([
      asMutation({ field: "stamps.end", type: "date" }, now()),
      asMutation({ field: "stamps.done", type: "date" }, now()),
      asMutation({ field: "open", type: "boolean" }, false),
    ]);
  }, [onNext]);

  const handleCompleteAndNext = useCallback(() => {
    handleComplete();
    onNext?.();
  }, [handleComplete, onNext]);

  const handleSkip = useCallback(() => {
    mutate([
      asMutation({ field: "stamps.done", type: "date" }, undefined),
      asMutation({ field: "stamps.end", type: "date" }, now()),
    ]);
    onNext?.();
  }, [onNext]);

  const handleJumpTo = useCallback(() => {
    onActive?.();
  }, []);

  const handleReset = useCallback(() => {
    mutate([
      asMutation({ field: "stamps.start", type: "date" }, undefined),
      asMutation({ field: "stamps.end", type: "date" }, undefined),
      asMutation({ field: "stamps.done", type: "date" }, undefined),
      asMutation({ field: "open", type: "boolean" }, true),
    ]);
  }, [active]);

  const handleTitleChanged = useCallback(
    (t: Maybe<string>) =>
      mutate(asMutation({ field: "title", type: "text" }, t)),
    [mutate]
  );

  const handleNotesChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "notes", type: "rich_text" }, n)),
    [mutate]
  );

  const handleBodyChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "body", type: "rich_text" }, n)),
    [mutate]
  );

  const withOpen = useCallback(
    (cb: Fn<void, void>) => () => {
      cb();
      if (!open) setOpen(true);
    },
    [open]
  );

  // Track start of agenda item when it's running and active
  useEffect(() => {
    if (running && active && !agenda.stamps?.start) {
      mutate(asMutation({ field: "stamps.start", type: "date" }, now()));
    }
  }, [running, active, agenda.stamps?.start]);

  return (
    <div className={cx(styles.agenda, running && active && styles.active)}>
      <Container padding="none" inset="left">
        <div
          ref={ref}
          style={css}
          className={styles.header}
          onClick={respectHandled(() => !(running && active) && setOpen(!open))}
        >
          {agenda.color && (
            <BackgroundColor color={agenda.color} opacity={0.3} />
          )}
          {dropping && <DropHighlight />}

          <ContextMenu
            actions={
              <>
                <ContextItem
                  text="Delete"
                  icon={TrashAlt}
                  onClick={() => onDelete?.(agenda)}
                />
              </>
            }
          >
            <SpaceBetween>
              <HStack gap={4}>
                <Icon
                  icon={
                    running && active
                      ? StatusLive
                      : open
                      ? AngleDownIcon
                      : AngleRightIcon
                  }
                />

                <div onClick={handle}>
                  <EditableText
                    key={"agenda-title"}
                    text={agenda.title}
                    textSize="medium"
                    placeholder="To discuss"
                    className={cx(
                      styles.agendaTitle,
                      completed && !active && styles.strikethrough
                    )}
                    autoFocus={focus}
                    onBlur={handleTitleBlur}
                    updateOn="change"
                    onChange={handleTitleChanged}
                  />
                </div>

                {hosting && running && !active && (
                  <Button
                    iconSize="small"
                    size="small"
                    onClick={withHardHandle(handleJumpTo)}
                  >
                    Move focus here
                  </Button>
                )}
              </HStack>

              <HStack>
                <HStack
                  gap={0}
                  fit="content"
                  className={styles.onHover}
                  onClick={withHandle(() => {})}
                >
                  {!open && !isEmpty(agenda.body) && (
                    <Button subtle size="small" onClick={withOpen(() => {})}>
                      <Text subtle>Details</Text>
                    </Button>
                  )}

                  {!showNotes && (
                    <Button
                      subtle
                      size="small"
                      variant={showNotes ? "primary" : "secondary"}
                      onClick={withOpen(() => setShowNotes(true))}
                    >
                      <Text subtle>Notes</Text>
                    </Button>
                  )}

                  {!showActions && (
                    <Button
                      subtle
                      size="small"
                      variant={showActions ? "primary" : "secondary"}
                      onClick={withOpen(() => setShowActions(true))}
                    >
                      <Text subtle>Actions</Text>
                    </Button>
                  )}

                  <ColorSelect
                    color={agenda.color}
                    onChange={(c) =>
                      mutate(asMutation({ field: "color", type: "text" }, c))
                    }
                  >
                    <Button subtle size="small">
                      <Text subtle>Color</Text>
                    </Button>
                  </ColorSelect>
                </HStack>

                <DurationPicker
                  mins={agendaTime}
                  maxTime={(availableTime || 0) + (agendaTime || 0)}
                  onChange={(v) =>
                    mutate(
                      asMutation(
                        { field: "custom.allocated", type: "number" },
                        v
                      )
                    )
                  }
                >
                  <Button subtle size="small" onClick={withHandle(() => {})}>
                    {running && agendaTime ? (
                      <Text subtle>
                        {runningTime}/{agendaTime} mins
                      </Text>
                    ) : running && !agendaTime ? (
                      <Text subtle>{runningTime} mins</Text>
                    ) : (
                      <Text subtle>
                        {agendaTime ? `${agendaTime} mins` : "Time"}
                      </Text>
                    )}
                  </Button>
                </DurationPicker>
              </HStack>
            </SpaceBetween>
          </ContextMenu>
        </div>
      </Container>

      <VStack
        className={cx(styles.body, isEmpty(agenda.body) && styles.empty)}
        fit="container"
        gap={18}
      >
        {open && (
          <RealTimeDocumentEditor
            entity={agenda.id}
            field="body"
            content={agenda.body}
            className={styles.agendaBody}
            newLineSpace="small"
            onChanged={handleBodyChanged}
            placeholder="What needs to be discussed?"
          />
        )}

        {open && showNotes && (
          <Section title="Notes" inset={false} className={styles.agendaSection}>
            <RealTimeDocumentEditor
              entity={agenda.id}
              field="notes"
              placeholder="Shared meeting notes..."
              content={agenda.notes}
              newLineSpace="small"
              className={styles.notes}
              textSize="default"
              onChanged={handleNotesChanged}
            />
          </Section>
        )}

        {open && showActions && (
          <Section
            title="Actions"
            inset={false}
            className={styles.agendaSection}
          >
            <VStack gap={0}>
              <Container padding="none" inset="horizontal">
                <EntityList items={actions || []} />
              </Container>

              <AddEntityInput
                canUseTemplates={false}
                canLink={false}
                placeholder="New action @person"
                onAdded={handleAddAction}
                defaults={{
                  refs: {
                    agenda: [toRef(agenda)],
                    meeting: [toRef(meeting)],
                  },
                }}
                source={{ type: "action", scope: nestedSource.scope }}
              />
            </VStack>
          </Section>
        )}

        {open && hosting && running && active && (
          <HStack className={styles.agendaActions} gap={4}>
            <Button
              size="small"
              variant="danger"
              onClick={handleCompleteAndNext}
            >
              Mark done
            </Button>
            <Button size="small" variant="secondary" onClick={handleSkip}>
              Leave open
            </Button>
            <Button size="small" subtle onClick={handleReset}>
              Reset
            </Button>
          </HStack>
        )}
      </VStack>
    </div>
  );
};

const EnableSoundsDialog = () => {
  const [enabled, setEnabled] = useStickyState<boolean>(
    false,
    "sounds-enabled"
  );
  const [play] = useSound("/SND_success.m4a");
  const handleEnabled = useCallback(() => {
    play();
    setEnabled(true);
    explode();
  }, [play]);

  if (enabled) {
    return <></>;
  }

  return (
    <Dialog
      title="Enable sounds"
      description="To enable sounds, please allow notifications from your browser."
      actions={
        <>
          <Button variant="primary" onClick={handleEnabled}>
            Allow notifications
          </Button>
        </>
      }
    />
  );
};
