import { map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { getMessages, Message } from "@api/integrations/slack";
import { Link, Ref } from "@api";

import { useCurrentUser } from "@state/workspace";
import { useCreateThread, useReplyInThread } from "@state/resources";
import { useLazyGetPerson } from "@state/persons";

import { redirect } from "@utils/url";
import { useAsyncEffect } from "@utils/effects";
import { required, when, WithRequired } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { toChannelId, toThreadId } from "@utils/slack";

import { Button } from "@ui/button";
import { Text } from "@ui/text";
import { MessageBox } from "@ui/message-box";
import { OpenIn, Slack, SlackColor, TimesIcon } from "@ui/icon";
import { SlackMessage, SlackText } from "@ui/slack-message";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Container } from "@ui/container";

import styles from "./slack-thread.module.css";

export type Props = {
  header?: "subtle" | "white";
  showInitialMessage?: boolean;
  onClose?: Fn<void, void>;

  // Either provide a thread url
  thread?: string;

  // Or a channel and title
  defaultChannel?: string;
  defaultTitle?: string;
  defaultAuthor?: Ref;
  onNewThread?: Fn<Link, void>;
};

export const SlackThread = ({
  thread,
  defaultTitle,
  defaultChannel,
  defaultAuthor: _defaultAuthor,
  onNewThread,
  showInitialMessage: showThreadMessage = false,
  header = "white",
  onClose,
}: Props) => {
  const me = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [allMessages, setMessages] = useState<Message[]>();
  const [headerMessage, ...messages] = allMessages || [];
  const [replying, setReplying] = useState(false);
  const isSaved = !!thread;
  const defaultAuthor = useLazyGetPerson(_defaultAuthor?.id || "");

  const channelId = useMemo(
    () => when(thread, toChannelId) || defaultChannel,
    [thread]
  );
  const threadId = useMemo(() => when(thread, toThreadId), [thread]);
  const createThread = useCreateThread(defaultChannel);
  const replyInThread = useReplyInThread(channelId, threadId);

  const openInSlack = useCallback(
    () => when(thread, (t) => redirect(t, true)),
    [thread]
  );

  const onReply = useCallback(
    async (reply: string) => {
      setReplying(true);
      if (!reply || replying) {
        return;
      }

      if (thread) {
        const { message } = await replyInThread({ message: reply, author: me });
        setMessages((m) => [...(m || []), message]);
      } else {
        const { link, message } = await createThread(
          {
            message: required(
              defaultTitle,
              () => "No default title passed in."
            ),
            author: defaultAuthor,
          },
          { message: reply, author: me }
        );
        link && onNewThread?.({ url: link, text: defaultTitle || "" });
        setMessages((m) => [...(m || []), message]);
      }

      setReplying(false);
    },
    [onNewThread, replyInThread, createThread, replying, me, thread]
  );

  useAsyncEffect(async () => {
    if (!thread) {
      setMessages([]);
      return;
    }

    if (!channelId || !threadId) {
      throw new Error("Invalid slack link.");
    }

    setLoading(true);

    const messages = await getMessages(channelId, threadId);

    setMessages(messages || []);
    setLoading(false);
  }, [thread, channelId, threadId]);

  return (
    <SpaceBetween direction="vertical" fit="content" className={styles.thread}>
      <SpaceBetween
        fit="container"
        className={cx(
          header === "subtle" && styles.subtleHeader,
          header === "white" && styles.whiteHeader
        )}
      >
        {isSaved && (
          <Button
            subtle
            icon={SlackColor}
            onClick={openInSlack}
            className={styles.threadTitle}
          >
            {when(headerMessage?.text, (t) => (
              <SlackText text={t} className={styles.clip} />
            )) || "Thread"}
          </Button>
        )}

        {!isSaved && (
          <Button subtle icon={Slack} className={styles.threadTitle}>
            <Text subtle>{defaultTitle || "Thread"}</Text>
          </Button>
        )}

        <HStack gap={0}>
          {thread && <Button subtle onClick={openInSlack} icon={OpenIn} />}
          {onClose && (
            <Button subtle onClick={() => onClose?.()} icon={TimesIcon} />
          )}
        </HStack>
      </SpaceBetween>

      <FillSpace width="container" height="content">
        <Container
          padding="none"
          stack="vertical"
          className={styles.messages}
          width="container"
          height="container"
        >
          {loading && "loading..."}
          {map(showThreadMessage ? allMessages : messages, (m) => (
            <SlackMessage key={m.ts} message={m} />
          ))}
        </Container>
      </FillSpace>

      <Container stack="horizontal" fit="container" className={styles.bottom}>
        {me && (
          <MessageBox me={me} onMessage={onReply} placeholder="Reply..." />
        )}
      </Container>
    </SpaceBetween>
  );
};

export const NewSlackThread = (
  props: WithRequired<Props, "defaultChannel" | "defaultTitle" | "onNewThread">
) => <SlackThread {...props} />;
