import { useCallback, useState } from "react";

import { HasNotes, Integration, Link, NoteType, Ref, Status } from "@api";

import { newNote, useCreateNote } from "@state/notes";
import { useReplyOrCreateThread } from "@state/resources";
import { useCurrentUser, useActiveWorkspaceId } from "@state/workspace";

import { Maybe, when } from "@utils/maybe";
import { toScope } from "@utils/scope";
import { Fn } from "@utils/fn";
import { toUpdateText } from "@utils/slack";
import { toMarkdown } from "@utils/rich-text";

import { Button } from "@ui/button";
import { Field } from "@ui/input";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { TextBox } from "@ui/rich-text";
import { PeopleStack } from "@ui/people-stack";
import { NotesAdd, SlackColor } from "@ui/icon";
import { Modal } from "@ui/modal";
import { SlackSelect } from "@ui/select/slack";
import { PropertyValue } from "./property-value";
import { omitValue } from "@utils/enum";

interface Props {
  entity: HasNotes;
  type: NoteType;
  channel?: string;
  status?: Status;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const NoteCreateDialog = ({
  entity,
  type,
  status: _status,
  channel: _channel,
  onCancel,
  onSaved,
}: Props) => {
  const me = useCurrentUser();
  const workspace = useActiveWorkspaceId();
  const [saving, setSaving] = useState(false);
  const create = useCreateNote(entity);
  const [mode, setMode] = useState<"update" | "note">(
    when(type, (t) => omitValue(t, NoteType.Discussion)) || "update"
  );
  const [channel, setChannel] = useState(_channel);
  const [thread, setThread] = useState<string>();
  const post = useReplyOrCreateThread(channel, thread);
  const [note, setNote] = useState(
    newNote({
      refs: {
        followers: (entity as any).refs?.followers,
      },
      source: {
        ...entity.source,
        type: "note",
      },
    })
  );

  const onCreate = useCallback(async () => {
    let link: Maybe<Link> = undefined;

    setSaving(true);

    if (!me) {
      throw new Error("Missing authenticated user.");
    }

    if (mode === "update" && channel) {
      const { link: url } = await post(
        {
          message: toMarkdown(note.body) || "",
          author: me,
        },
        when(toUpdateText(entity, me), (message) => ({ message }))
      );

      link = url ? { text: "Slack Thread", url: url } : undefined;
    }

    const saved = create([
      { field: "type", type: "text", value: { text: type } },
      {
        field: "links",
        type: "links",
        value: {
          links: link ? [link] : undefined,
        },
      },
      {
        field: "title",
        type: "text",
        value: { text: note.title },
      },
      {
        field: "body",
        type: "rich_text",
        value: { rich_text: note.body },
      },
      {
        field: "refs.followers",
        type: "relations",
        value: { relations: note.refs?.followers },
      },
      {
        field: "author",
        type: "relation",
        value: { relation: { id: me.id } },
      },
    ]);

    onSaved?.(saved);

    setSaving(false);
  }, [post, onSaved, note, mode, me]);

  return (
    <Modal size="secondary" open={true} onOpenChanged={() => onCancel?.()}>
      <VStack gap={20} fit="container">
        <HStack gap={2}>
          <Button
            subtle
            icon={SlackColor}
            variant={mode === "update" ? "primary" : "secondary"}
            onClick={() => setMode("update")}
          >
            New message
          </Button>
          <Button
            variant={mode === "note" ? "primary" : "secondary"}
            subtle
            icon={NotesAdd}
            onClick={() => setMode("note")}
          >
            Leave a Note
          </Button>
        </HStack>

        <VStack gap={4} fit="container">
          <Field>
            <TextBox
              key={note.id}
              text={note.body}
              placeholder="Write your update..."
              onChanged={(rt) => setNote({ ...note, body: rt })}
              focus={true}
              size="two-line"
              submitOnEnter={false}
              onEnter={onCreate}
            />
          </Field>
          {mode === "update" ? (
            <Field label="Copy to slack thread" layout="horizontal">
              <HStack>
                <SlackSelect
                  position="bottom-right"
                  channel={channel}
                  thread={thread}
                  mode="thread"
                  placeholder="Select a channel..."
                  onChange={(c, t) => {
                    setChannel(c);
                    setThread(t);
                  }}
                />
              </HStack>
            </Field>
          ) : (
            <span />
          )}
          {mode === "update" && (
            <Field label="People to notify" layout="horizontal">
              <PropertyValue
                editable
                position="bottom-right"
                source={{
                  source: Integration.Traction,
                  type: "task",
                  scope: toScope(workspace),
                }}
                valueRef={{
                  field: "refs.followers",
                  type: "relations",
                  value: { relations: note?.refs?.followers },
                }}
                onChange={(fs) =>
                  setNote({
                    ...note,
                    refs: { ...note.refs, followers: fs?.relations },
                  })
                }
              >
                <Button subtle size="small">
                  <PeopleStack people={note.refs?.followers} />
                </Button>
              </PropertyValue>
            </Field>
          )}
        </VStack>
        <SpaceBetween>
          <span />
          <HStack>
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate}>
              {saving ? "Posting..." : "Post Update"}
            </Button>
          </HStack>
        </SpaceBetween>
      </VStack>
    </Modal>
  );
};
