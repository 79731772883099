import { useSetRecoilState } from "recoil";
import { Dictionary, flatten, keys, map, reduce, uniq, values } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, Ref, toTitleOrName } from "@api";

import {
  useDeleteEntitys,
  useLazyEntities,
  useManyNestedEntities,
} from "@state/generic";
import { ViewStoreAtom } from "@state/views";
import { clearTempUpdates, removeItem } from "@state/store";

import { composel, Fn } from "@utils/fn";
import { mask, maybeValues } from "@utils/object";
import { maybeMap } from "@utils/maybe";
import { isLocalID } from "@utils/id";
import { plural } from "@utils/string";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Dialog } from "@ui/dialog";
import { Fields } from "@ui/input";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const DeleteDialog = ({ targets, onCancel, onComplete }: Props) => {
  const pageId = usePageId();
  const entities = useLazyEntities(targets);
  const parentTypes = useMemo(
    () => uniq(maybeMap(entities, (e) => e.source?.type)),
    [entities]
  );
  const setViewStore = useSetRecoilState(ViewStoreAtom);
  const deletee = useDeleteEntitys(pageId);
  const [opts, setDelete] = useState<Partial<Record<EntityType, boolean>>>({});
  const { children, loading } = useManyNestedEntities(entities);
  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entities?.length) {
      return;
    }

    // If we're deleting a local view, just remove it form the store rather than deleting it
    if (entities?.[0]?.source.type === "view" && isLocalID(entities[0]?.id)) {
      setViewStore(
        composel(removeItem(entities[0]?.id), clearTempUpdates(entities[0]?.id))
      );
      return;
    }

    const allChildren = flatten(
      values(mask(children as Dictionary<Entity[]>, opts))
    );

    // Delete entity + all nested work
    deletee([...map(entities, "id"), ...map(allChildren, "id")]);

    // Callback
    onComplete?.();
  }, [deletee, entities, opts]);

  // Mark all as included
  useEffect(() => {
    setDelete(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      title="Confirm delete"
      description={
        entities?.length === 1
          ? `Do you want to delete this ${
              entities[0].source.type
            } (${toTitleOrName(entities[0])}) and all nested work?`
          : `Do you want to delete these ${entities?.length} selected
              ${parentTypes?.join("/")} and all nested work?`
      }
      onDismiss={() => onCancel?.()}
      actions={
        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="danger" onClick={handleSubmit}>
            {entities?.length === 1 ? "Delete" : "Delete all"}
          </Button>
        </HStack>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields>
              <Menu>
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled>
                    Delete {entities?.length} selected{" "}
                    {map(parentTypes, (t) => plural(t, entities?.length))?.join(
                      "/"
                    )}
                  </CheckMenuItem>

                  {!loading && !hasChildren && (
                    <MenuItem disabled icon={Check}>
                      No nested work
                    </MenuItem>
                  )}
                  {loading && (
                    <MenuItem disabled icon={SpinnerIcon}>
                      Collecting nested work...
                    </MenuItem>
                  )}

                  {map(children, (values, type) => (
                    <CheckMenuItem
                      key={type}
                      checked={opts[type as EntityType] ?? false}
                      onChecked={() =>
                        setDelete((d) => ({
                          ...d,
                          [type]: !(opts[type as EntityType] ?? false),
                        }))
                      }
                    >
                      Delete {values?.length || 0} nested{" "}
                      {plural(type, values?.length || 0)}
                    </CheckMenuItem>
                  ))}
                </MenuGroup>
              </Menu>
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};

export const DeleteEntitiesButton = ({
  targets,
  onCancel,
  onComplete,
}: Props) => {
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <Button
        variant="danger"
        onClick={() => setDeleting(true)}
        disabled={deleting}
        loading={deleting}
      >
        Delete
      </Button>

      {deleting && (
        <DeleteDialog
          targets={targets}
          onCancel={() => {
            setDeleting(false);
            onCancel?.();
          }}
          onComplete={() => {
            setDeleting(false);
            onComplete?.();
          }}
        />
      )}
    </>
  );
};
