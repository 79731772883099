import {
  FetchOptions,
  getOptimizedForFilter,
  getView,
  getViewsForParent,
  PropertyDef,
} from "@api";
import { cachedFuncByWorkspace } from "@state/workspace";

import { toMilliSeconds } from "@utils/time";

import { ViewState } from "./atoms";
import { toFullFilter } from "./utils";

export const getOptimizedItemsForView = async (
  view: ViewState,
  opts: Pick<FetchOptions, "since" | "archived" | "templates">,
  props: PropertyDef[]
) => {
  const filter = toFullFilter(view, props, opts);

  if (!filter || !view.source.scope) {
    return;
  }

  return await getOptimizedForFilter(
    { type: view.entity, scope: view.source.scope },
    filter,
    opts
  );
};

export const getOptimizedItemsForViewLoader = cachedFuncByWorkspace(
  () => getOptimizedItemsForView,
  toMilliSeconds("2 seconds"),
  ([v, o, p]) => v.id + o.archived + o.templates + p.length
);

export const getViewLoader = cachedFuncByWorkspace(
  () => getView,
  toMilliSeconds("2 seconds"),
  ([v]) => v
);

export const getViewsForParentLoader = cachedFuncByWorkspace(
  () => getViewsForParent,
  toMilliSeconds("10 seconds"),
  ([u]) => u
);
