import { Route, useLocation } from "react-router-dom";
import { last, map, takeRightWhile } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";

import { Entity, EntityType, ID } from "@api";

import { toTemplateViewId } from "@state/views";
import { useAllowedChildren } from "@state/databases";
import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { maybeTypeFromId } from "@utils/id";
import { takeUpTo } from "@utils/scope";

import { Box, Home } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RouteMenuItem } from "@ui/menu-item";
import { Button } from "@ui/button";

type Route = {
  pathname: string;
  type: Maybe<EntityType>;
  id: Maybe<ID>;
};
// TODO: Should be converted into a component similar to Routes
export const useSubRouter = (id: ID): Route => {
  const location = useLocation();
  return useMemo(() => {
    const parts = takeRightWhile(location.pathname.split("/"), (p) => p !== id);
    const lastPart = last(parts);
    const lastType = when(lastPart, maybeTypeFromId<EntityType>);
    return {
      pathname: parts?.join("/") || "/",
      type: lastType,
      id: lastType ? lastPart : undefined,
    };
  }, [location?.pathname, id]);
};

export const SubRouter = ({
  id,
  router,
}: {
  id: ID;
  router: Fn<Route, JSX.Element>;
}) => {
  const subroute = useSubRouter(id);
  return useMemo(() => router(subroute), [subroute]);
};

export const SubRoutesMenu = ({ entity }: { entity: Entity }) => {
  const location = useLocation();
  const toLabel = useEntityLabels(entity.source.scope);
  const childTypes = useAllowedChildren(entity, ["resource", "note", "view"]);
  const basePath = useMemo(
    () => takeUpTo(location.pathname, entity.id, true),
    [location.pathname, entity.id]
  );
  // Keeps current base bath and appends the subroute
  const toChildRoute = useCallback(
    (sub?: string) => when(sub, (s) => `${basePath}/${s}`) || basePath,
    [basePath]
  );

  return (
    <Menu>
      <MenuGroup>
        <RouteMenuItem text="Overview" icon={Home} route={toChildRoute()} />

        {map(childTypes, (type) => (
          <RouteMenuItem
            key={type}
            icon={Box}
            route={toChildRoute(
              toTemplateViewId(`${entity.source.type}-${type}`, {
                parent: entity.id,
                entity: type,
              })
            )}
          >
            {toLabel(type, { plural: true })}
          </RouteMenuItem>
        ))}
      </MenuGroup>
    </Menu>
  );
};
